import React from 'react';
import { ToastContainer } from 'react-toastify';

import Full from 'Containers/Full';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@brainhubeu/react-carousel/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';

import config from 'Config/Config';
import PackageJson from '../package.json';

function App(): JSX.Element {
    console.log(`Version: ${PackageJson.version} (${config.ENV})`);

    return (
        <div>
            <Full />
            <ToastContainer position='top-right' closeButton={false} />
        </div>
    );
}

export default App;
