import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILocation } from '@ddot/AddToCartCommon/lib/entities';
import { IHome, IHelloGoldPrice } from './Entities';

import controllers from './controllers';
import getWidgets from './controllers/getWidgets';

const initialState: IHome = {
    actions: {
        locations: false,
        goldPrice: true,
        featuredProducts: false,
        productWidgets: true,
    },
    isMaintenance: false,
    locations: [],
    featuredProducts: [],
    productWidgets: [],
    goldPrice: {
        gold375G: undefined,
        gold750: undefined,
        gold916B: undefined,
        gold916A: undefined,
        gold916: undefined,
        gold925: undefined,
        gold999: undefined,
        gold9999: undefined,
    },
    error: {
        locations: '',
        goldPrice: '',
        featuredProducts: '',
        productWidgets: '',
    },
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setIsMaintenance: (state, action: PayloadAction<boolean>) => {
            state.isMaintenance = action.payload;
        },
        setGoldPrices: (state, action: PayloadAction<IHelloGoldPrice>) => {
            state.actions.goldPrice = false;

            if (action.payload) {
                state.goldPrice.gold375G = action.payload.gold375G;
                state.goldPrice.gold750 = action.payload.gold750;
                state.goldPrice.gold916B = action.payload.gold916B;
                state.goldPrice.gold916A = action.payload.gold916A;
                state.goldPrice.gold916 = action.payload.gold916;
                state.goldPrice.gold925 = action.payload.gold925;
                state.goldPrice.gold999 = action.payload.gold999;
                state.goldPrice.gold9999 = action.payload.gold9999;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(controllers.homeGetLocation.pending, (state) => {
            state.actions.locations = true;
            state.error.locations = '';
        });
        builder.addCase(controllers.homeGetLocation.fulfilled, (state, action: PayloadAction<ILocation[]>) => {
            state.actions.locations = false;
            state.locations = action.payload;
        });
        builder.addCase(controllers.homeGetLocation.rejected, (state, action) => {
            state.actions.locations = false;
            if (action.payload) state.error.locations = action.payload;
        });
        builder.addCase(controllers.homeGetFeaturedProducts.pending, (state) => {
            state.actions.featuredProducts = true;
            state.error.featuredProducts = '';
        });
        builder.addCase(controllers.homeGetFeaturedProducts.fulfilled, (state, action) => {
            state.actions.featuredProducts = false;
            if (action.payload) {
                state.featuredProducts = action.payload;
            }
        });
        builder.addCase(controllers.homeGetFeaturedProducts.rejected, (state, action) => {
            state.actions.featuredProducts = false;
            if (action.payload) {
                state.error.featuredProducts = action.payload;
            }
        });
        builder.addCase(controllers.homeGetGoldPrice.pending, (state) => {
            state.actions.goldPrice = true;
            state.error.goldPrice = '';
        });
        builder.addCase(controllers.homeGetGoldPrice.fulfilled, (state, action) => {
            state.actions.goldPrice = false;
            if (action.payload) {
                state.goldPrice = action.payload;
            }
        });
        builder.addCase(controllers.homeGetGoldPrice.rejected, (state, action) => {
            state.actions.goldPrice = false;
            if (action.payload) state.error.goldPrice = action.payload;
        });
        builder.addCase(getWidgets.pending, (state) => {
            state.actions.productWidgets = true;
            state.productWidgets = [];
        });
        builder.addCase(getWidgets.fulfilled, (state, action) => {
            state.actions.productWidgets = false;
            state.productWidgets = action.payload;
        });
    },
});

export default {
    actions: homeSlice.actions,
    reducers: homeSlice.reducer,
};
