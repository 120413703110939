/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import {
    headerCollapse,
    headerCollapseOpen,
    collapseEntry,
    collapseEntry2,
} from 'Navigation/Styles/HeaderStyle';
import colors from 'Themes/colors';

import Selectors from 'Redux/Selectors';
import NavActions from 'Services/Navigation/Actions';
import { IProductCategory } from '@ddot/AddToCartCommon/lib/entities';

type Title = 'Home' | 'Jewellery' | 'Collections' | 'About Us';

const HeaderCollapse = (): JSX.Element => {
    const [isOpen, setOpen] = useState(false);
    const [isProductsOpen, setProductsOpen] = useState(false);
    const [isCollectionsOpen, setCollectionsOpen] = useState(false);

    const categories = useSelector(Selectors.getProductsCategories);

    useEffect(() => {
        setOpen(false);
    }, [window.location.pathname]);

    useEffect(() => {
        // auto-close all further collapsibles when navmenu is collapsed
        if (!isOpen) {
            setProductsOpen(false);
            setCollectionsOpen(false);
        }
    }, [isOpen]);

    const toggleOpen = () => {
        setOpen((prevState) => !prevState);
    };

    const toggleProductsOpen = () => {
        setProductsOpen((prevState) => !prevState);
    };

    const toggleCollectionsOpen = () => {
        setCollectionsOpen((prevState) => !prevState);
    };

    const onNavToHome = () => {
        NavActions.navToHome();
        toggleOpen();
    };

    const onNavToCategory = (category: IProductCategory) => {
        NavActions.navToProductListingWithCategory(category.id);
        toggleOpen();
    };

    const onNavToAllProducts = () => {
        toggleOpen();

        if (window.location.pathname !== '/categories') {
            NavActions.navToProductListing();
        }
    };

    const onNavToAboutUs = () => {
        toggleOpen();
        NavActions.navToAboutUs();
    };

    const renderCategories = () => {
        const ascCategory = categories.slice();
        if (!ascCategory.length) return false;

        const sortedCategory = ascCategory.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        return sortedCategory.map(category => {
            const { id, name } = category;
            return (
                <div
                    key={id}
                    css={collapseEntry2}
                    onClick={() => onNavToCategory(category)}
                    onKeyPress={() => onNavToCategory(category)}
                    role='button'
                    tabIndex={0}
                >
                    {name}
                </div>
            );
        });
    };

    const renderCollapseEntry = (title: Title, onClick: () => void) => {
        return (
            <div
                css={collapseEntry}
                onClick={onClick}
                onKeyPress={onClick}
                role='button'
                tabIndex={0}
            >
                {title}
            </div>
        );
    };

    return (
        <Row className='d-flex d-lg-none m-0'>
            <Col
                xs={12}
                css={headerCollapse}
            >
                <div
                    onClick={toggleOpen}
                    onKeyPress={toggleOpen}
                    role='button'
                    tabIndex={0}
                >
                    Menu
                </div>
            </Col>
            <Collapse
                isOpen={isOpen}
                css={{
                    width: '100%',
                    borderBottom: `1px solid ${colors.black}`,
                    marginTop: 10,
                }}
            >
                {renderCollapseEntry('Home', onNavToHome)}
                {renderCollapseEntry('Jewellery', toggleProductsOpen)}
                <Collapse isOpen={isProductsOpen}>
                    <div
                        css={collapseEntry2}
                        onClick={onNavToAllProducts}
                        onKeyPress={onNavToAllProducts}
                        role='button'
                        tabIndex={0}
                    >
                        All Jewellery
                    </div>
                    {renderCategories()}
                </Collapse>
                {renderCollapseEntry('Collections', toggleCollectionsOpen)}
                {/* <Collapse isOpen={isCollectionsOpen}>
                    {['New In', 'More Collections', 'Special Collections'].map(
                        (category) => {
                            return (
                                <div
                                    key={category}
                                    css={collapseEntry2}
                                    onClick={() => {
                                        // NavActions.navToProductListing(category);
                                        toggleOpen();
                                    }}
                                    onKeyPress={() => {
                                        // NavActions.navToProductListing(category);
                                        toggleOpen();
                                    }}
                                    role='button'
                                    tabIndex={0}
                                >
                                    {category}
                                </div>
                            );
                        },
                    )}
                </Collapse> */}
                {renderCollapseEntry('About Us', onNavToAboutUs)}
            </Collapse>
        </Row>
    );
};

export default HeaderCollapse;
