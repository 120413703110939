import { IProduct } from '@ddot/AddToCartCommon/lib/entities';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Selectors from 'Redux/Selectors';

import { AsyncThunkAPI } from 'Redux/types';

export default createAsyncThunk<
    IProduct[] | undefined,
    void,
    AsyncThunkAPI
>('home/getFeaturedProducts', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const categories = Selectors.getProductsCategories(state);
    const productList = Selectors.getProductsList(state);

    const getFeaturedProducts = () => {
        let featuredProducts;
        if (categories.length) {
            const randomInt = (max: number) => {
                return Math.floor(Math.random() * max);
            };

            const featuredCategory = categories[randomInt(categories.length)];

            const products = productList.filter(product => product.categoryId === featuredCategory.id);

            let max = 5;
            if (products && products.length < 5) {
                max = products.length;
            } if (products && products.length >= 5) {
                max = Math.floor(Math.random() * (products.length - 5) + 5);
            }

            let min = 0;
            if (max >= 5) {
                min = max - 4;
            }

            featuredProducts = products.slice(min, max);
        }
        return featuredProducts;
    };

    try {
        return getFeaturedProducts();
    } catch (error: any) {
        const errorMessage = error || 'Something went wrong. Please try again.';

        return thunkAPI.rejectWithValue(errorMessage);
    }
});
