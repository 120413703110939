/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import { ActivityIndicator } from 'react-native-web';
import NavActions from 'Services/Navigation/Actions';

import { RootState } from 'Redux/store';
import { connect } from 'react-redux';
import Selectors from 'Redux/Selectors';
import { cartTitle } from '../Styles/CartStyles';
import {
    continuePaymentButton,
} from '../Styles/ShippingAddressStyles';

interface CreditCartPaymentProps {
    loading: boolean;
}

const CreditCardPayment = (props: CreditCartPaymentProps): JSX.Element => {
    const { loading } = props;

    const onProceedClick = () => {
        NavActions.navToSubmitCyberSourceInfo();
    };

    return (
        <Container fluid style={{ paddingTop: 20 }}>
            <Row>
                <Col style={{ padding: 0 }}>
                    <div css={cartTitle}>
                        Credit/Debit Card
                    </div>
                </Col>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    onClick={onProceedClick}
                    css={continuePaymentButton}
                >
                    {loading ? (
                        <ActivityIndicator color='white' />
                    ) : (
                        'PROCEED'
                    )}
                </Button>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getCartCyberSourceInfoAttempting(state),
    error: Selectors.getCartCyberSourceInfoError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    //
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardPayment);
