import { JemisysErrorTypes } from '@ddot/AddToCartCommon/lib/useCase';

import uiSortProducts from './sortProducts';
import uiStockCheck from './stockCheck';
import uiStockCheckAndLock from './stockCheckAndLock';
import uiSearchProducts from './searchProduct';
import uiFilterProducts from './filterProducts';
import uiGetOrderDetails from './getOrderDetails';

export const ErrorTypes = JemisysErrorTypes.OrderErrorTypes;

export default {
    uiSortProducts,
    uiStockCheck,
    uiStockCheckAndLock,
    uiSearchProducts,
    uiFilterProducts,
    uiGetOrderDetails,
};
