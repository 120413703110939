/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import Utils from 'Lib/Utils';

import { IProduct, IProductVariant } from '@ddot/AddToCartCommon/lib/entities';
import {
    productDetailsText,
    productDropdown,
    productVariationText,
} from '../Styles/ProductDetailsStyles';

interface PropType {
    product: IProduct;
    selectedVariant?: IProductVariant;
    onVariantSelected(variant: IProductVariant): void;
}

interface ParsedProductVariant extends IProductVariant {
    displayString: string;
}

const VariantSelector = (props: PropType): JSX.Element | null => {
    const { product, selectedVariant, onVariantSelected } = props;

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const variants = Utils.Product.getVariants(product);
    if (!variants.length) return null;

    const parsedVariants: ParsedProductVariant[] = variants.map((item: IProductVariant) => {
        const displayString = Utils.Product.getItemVariantSizeLengthString(item);

        return {
            ...item,
            displayString,
        };
    });

    const sortedVariants = parsedVariants.sort((a: ParsedProductVariant, b: ParsedProductVariant) => {
        const stringA = a.displayString.toUpperCase();
        const stringB = b.displayString.toUpperCase();

        if (stringA < stringB) return 1;
        if (stringA > stringB) return -1;
        return 0;
    });

    const renderSelectedVariant = (): string => {
        if (!selectedVariant) return 'Please choose a variant';
        return Utils.Product.getItemVariantSizeLengthString(selectedVariant);
    };

    return (
        <div>
            <div css={productDetailsText}>Select Variation</div>
            <Dropdown isOpen={isOpen} toggle={toggle}>
                <DropdownToggle css={productDropdown} caret>
                    {renderSelectedVariant()}
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: '210px', overflowY: 'scroll' }}>
                    {sortedVariants.map((variant) => {
                        return (
                            <DropdownItem
                                css={productVariationText}
                                key={variant.displayString}
                                onClick={() => onVariantSelected(variant)}
                            >
                                {variant.displayString}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

VariantSelector.defaultProps = {
    selectedVariant: undefined,
};

export default VariantSelector;
