import { createAsyncThunk } from '@reduxjs/toolkit';

import cartSlice from 'Redux/slices/cart';
import { AsyncThunkAPI } from 'Redux/types';

import { ICartProduct } from '@ddot/AddToCartCommon/lib/entities';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { ErrorTypes } from '.';

export interface IStockCheckResponse {
    stockUnavailable: ICartProduct[];
}

export interface IStockCheckParams {
    products: ICartProduct[];
}

export default createAsyncThunk<
    IStockCheckResponse,
    IStockCheckParams,
    AsyncThunkAPI
>('ui/stockCheckAndLock', async (params, thunkAPI) => {
    const { products } = params;

    const response = await thunkAPI.extra.useCases.order.stockCheck.execute({ products });

    if (response.status === UseCaseResponseStatus.Success) {
        const { stockUnavailable, products: stockCheckedProducts } = response.data;

        // some items are out of stock
        if (stockUnavailable.length > 0) return { stockUnavailable };

        // if all jewellery and gold items are in stock, we now lock the products
        const lockResponse = await thunkAPI.extra.useCases.order.lockProducts.execute({ products: stockCheckedProducts });

        if (lockResponse.status === UseCaseResponseStatus.Success) {
            if (lockResponse.data.stockUnavailable.length > 0) {
                // some items cannot be locked correctly
                return { stockUnavailable: lockResponse.data.stockUnavailable };
            }

            thunkAPI.dispatch(cartSlice.actions.cartSetCartItemAvailableVariant(stockCheckedProducts));
        } else {
            return thunkAPI.rejectWithValue(lockResponse.data.title || 'Some items are out of stock. Please try again.');
        }

        return { stockUnavailable };
    }

    if (response.data.type === ErrorTypes.StockCheckError.ServerError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again');
    }

    return thunkAPI.rejectWithValue('Something went wrong, please try again later');
});
