import { ICartProduct, IOrder, IProduct, IProductCategory } from '@ddot/AddToCartCommon/lib/entities';
import { IUi } from './Entities';

const getAuthSignUpModalVisible = (state: IUi): boolean => state.auth.signUpModalVisible;
const getAuthIsEmailSent = (state: IUi): boolean => state.auth.isEmailSent;
const getAuthUserId = (state: IUi): string => state.auth.userId;

const getAuthEmail = (state: IUi): string => state.auth.email;
const getAuthUuid = (state: IUi): string => state.auth.uuid;

const getProductList = (state: IUi): IProduct[] => state.productList.products;
const getProductCurrentCategory = (state: IUi): IProductCategory | null => state.productList.currentCategory;

const getStockUnavailableVisible = (state: IUi): boolean => state.cart.stockUnavailableVisible;

const getStockCheckAttempting = (state: IUi): boolean => state.actions.stockCheck;
const getStockCheckUnavailableProduct = (state: IUi): ICartProduct[] => state.stockCheck.itemsOutOfStock;
const getStockCheckError = (state: IUi): string => state.error.stockCheck;

const getIsEditingProfile = (state: IUi): boolean => state.user.isEditingProfile;

const getOrderDetails = (state: IUi): IOrder => state.orderDetails;

const getSearchQuery = (state: IUi): string => state.productList.currentSearchQuery || '';
const getSuccessfulSearchQuery = (state: IUi): string => state.productList.successSearchQuery;

const getSearchProductsAttempting = (state: IUi): boolean => state.actions.searchProducts;
const getSearchProductsError = (state: IUi): string | undefined => state.error.searchProducts;

const getFilteredProducts = (state: IUi): IProduct[] => state.filteredProducts;

const getOrderId = (state: IUi): string => state.orderDetails?.id || '';

const getGetOrderDetailsAttempting = (state: IUi): boolean => state.actions.getOrderDetails || false;
const getGetOrderDetailsError = (state: IUi): string => state.error.getOrderDetails || '';

export default {
    getAuthSignUpModalVisible,
    getAuthIsEmailSent,
    getAuthUserId,

    getAuthEmail,
    getAuthUuid,

    getProductList,
    getProductCurrentCategory,

    getSearchQuery,
    getSuccessfulSearchQuery,

    getSearchProductsAttempting,
    getSearchProductsError,

    getStockUnavailableVisible,

    getStockCheckAttempting,
    getStockCheckUnavailableProduct,
    getStockCheckError,

    getIsEditingProfile,

    getOrderDetails,

    getFilteredProducts,

    getOrderId,

    getGetOrderDetailsAttempting,
    getGetOrderDetailsError,
};
