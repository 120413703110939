import { css } from '@emotion/react';
import font from 'Themes/fonts';
import colors from '../../../Themes/colors';

const { black, white, orange } = colors;

export const checkoutAsGuestButton = css({
    width: '65%',
    backgroundColor: '#BF7247',
    height: 48,
    fontSize: 18,
    fontFamily: font.primary,
    color: colors.white,
    border: `2px solid ${colors.primary}`,
    borderRadius: '5px',
    '&:hover': { background: white, borderColor: orange, color: orange },
});

export const LoginContainer = css({
    display: 'flex',
    flexDirection: 'column-reverse',
    '@media(min-width: 1000px)': {
        display: 'flex',
        flexDirection: 'row',
    },
});

export const leftContainer = css({
    background: 'linear-gradient(180deg, #BF7247 0%, #DBAA8D 100%)',
    height: '700px',
});

export const rightContainer = css({});

export const ContainerAlignCenter = css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
});

export const leftTitle = css({
    font: '25px Archivo-Regular, serif',
    fontWeight: '700',
    color: white,
    marginTop: '30px',
    marginBottom: '30px',
    '@media(min-width: 1200px)': {
        font: '35px Archivo-Regular, serif',
        marginTop: '50px',
        marginBottom: '50px',
    },
});

export const InputStyle = css({
    width: '100%',
    font: '14px Archivo-Regular, serif',
    color: '#949494',
    '@media(min-width: 1200px)': {
        width: '65%',
        font: '16px Archivo-Regular, serif',
    },
});

export const CheckBoxContainer = css({
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    alignItems: 'center',
    width: '78%',
    font: '12px Archivo-Regular, serif',
    color: white,
    '@media(min-width: 1200px)': {
        width: '65%',
        font: '15px Archivo-Regular, serif',
    },
});

export const ButtonStyleLeft = css({
    textAlign: 'center',
    width: '65%',
    font: '18px Archivo-Regular, sans-serif',
    fontWeight: '500',
    color: white,
    background: 'none',
    borderColor: white,
    '&:hover': { background: white, borderColor: orange, color: orange },
    marginTop: '10px',
    padding: '13px 0px',
    paddingRight: '20px',
    marginBottom: '50px',
});

export const CloseContainer = css({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: '20px',
    paddingRight: '20px',
});

export const RightTitle = css({
    font: '25px Archivo-Regular, serif',
    fontWeight: '700',
    color: black,
    marginTop: '30px',
    marginBottom: '30px',
    '@media(min-width: 1200px)': {
        font: '35px Archivo-Regular, serif',
        marginTop: '50px',
        marginBottom: '50px',
    },
});

export const CheckBoxContainerRight = css({
    font: '12px Archivo-Regular, serif',
    color: black,
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    '@media(min-width: 1200px)': {
        width: '64%',
        font: '15px Archivo-Regular, serif',
    },
});

export const CheckBoxOuterContainerRight = css({
    alignItems: 'center',
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '@media(min-width: 1200px)': {
        width: '65%',
    },
});

export const ForgotPasswordStyle = css({
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    font: '15px Archivo-Regular, serif',
    color: orange,
    textDecorationLine: 'underline',
});

export const ButtonStyleRight = css({
    width: '65%',
    borderColor: white,
    '&:hover': { background: white, borderColor: orange, color: orange },
    marginTop: '40px',
    marginBottom: '40px',
});

export const nestedSignupModal = css({
    background: 'linear-gradient(180deg, #BF7247 0%, #DBAA8D 100%)',
});

export const nestedSignupModalTitle = css({
    font: '25px Archivo-Regular, serif',
    fontWeight: '700',
    color: white,
    marginTop: '10px',
    marginBottom: '10px',
    '@media(min-width: 1200px)': {
        font: '35px Archivo-Regular, serif',
        marginTop: '15px',
        marginBottom: '15px',
    },
});

export const nestedSignupModalInput = css({
    width: '80%',
    font: '14px Archivo-Regular, serif',
    color: '#949494',
    height: '48px',
    '@media(min-width: 1200px)': {
        width: '100%',
        font: '16px Archivo-Regular, serif',
    },
});

export const nestedSignupModalSubmitButton = css({
    width: '65%',
    font: '18px Archivo-Regular, sans-serif',
    borderRadius: '5px',
    fontWeight: '500',
    color: white,
    background: 'none',
    borderColor: white,
    padding: '13px 0px',
    '&:hover': { background: white, borderColor: orange, color: orange },
    marginTop: '5px',
});

export const loginInput = css({
    width: '80%',
    font: '14px Archivo-Regular, serif',
    color: '#949494',
    padding: '0px 12px',
    marginBottom: '25px',
    '@media(min-width: 1200px)': {
        width: '65%',
        font: '16px Archivo-Regular, serif',
    },
});

export const formGroupCss = css({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
});

export const signUpError = css({
    color: 'white',
});
