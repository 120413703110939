/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { Col, Container, Row } from 'reactstrap';

import colors from 'Themes/colors';
import Selectors from 'Redux/Selectors';
import Actions from 'Redux/Actions';
import Button from 'Components/Button';
import ErrorMessage from 'Components/Common/ErrorMessage';

import Utils from 'Lib/Utils';

import ProductList from './Components/ProductList';
import CartBreadcrumbs from './Components/CartBreadcrumbs';
import OrderSummaryReview from './Components/OrderSummaryReview';
import OrderReviewSection from './Components/OrderReviewSection';
import GoldPriceChangeAlert from './Components/GoldPriceChangeAlert';
import NavActions from '../../Services/Navigation/Actions';
import ModalUnavailableProducts from './Components/ModalUnavailableProducts';

import { cartContainer, cartTitle } from './Styles/CartStyles';
import { orderSummaryContainer } from './Styles/ShippingAddressStyles';
import {
    orderReviewSection,
    orderReviewSectionTitle,
    orderReviewSectionTitleContainer,
    orderReviewText,
    // orderReviewEditText,
    // placeOrderButton,
    placeOrderInfo,
} from './Styles/OrderReviewStyles';

const OrderReview = (props) => {
    const { cartItems, shippingFee, shippingAddress, customerRemarks, stockCheckAndLock,
        stockCheckLoading, stockCheckError, clearStockCheckUnavailableProducts, goldPrice, placeOrder, placeOrderLoading, placeOrderError, placeOrderGuest, getCartIsGuest } = props;

    useEffect(() => {
        return () => {
            clearStockCheckUnavailableProducts();
        };
    }, []);

    let total = 0;

    cartItems.forEach(item => {
        const { selectedVariant, quantity } = item;
        const variantPrice = Utils.Jemisys.getItemVariantPrice(item, selectedVariant, goldPrice);
        total += (variantPrice * quantity);
    });

    if (!cartItems.length || !shippingAddress || !shippingAddress.address1) {
        NavActions.navToCart();
    }

    // const asBilling = shippingAddress.customAttributes.find((x) => x.name === 'asBilling');

    // TODO: get discount price from promoCode
    const discountPrice = 0;

    const orderSummary = {
        shippingFee,
        subTotal: total,
        currency: 'MYR',
        promoCode: null,
        discountPrice,
        // laborCost: total.totalLaborCost,
        totalCost: total + shippingFee - discountPrice,
    };

    const paymentMethodEnum = {
        FPXOnline: 1,
        CreditCard: 2,
        BankTransfer: 3,
        eGHL: 4,
    };

    const onProceed = async () => {
        const totalPrice = total + shippingFee;
        const paymentMethod = paymentMethodEnum.CreditCard;

        stockCheckAndLock(cartItems);

        if (getCartIsGuest) {
            await placeOrderGuest(totalPrice, paymentMethod, shippingFee);
        } else await placeOrder(totalPrice, paymentMethod, shippingFee);
    };

    const renderCustomerRemarks = () => {
        if (!customerRemarks) return false;

        return (
            <OrderReviewSection
                title='Customer Remarks'
                onClick={NavActions.navToShippingAddress}
            >
                <Row css={orderReviewSection}>
                    <Col>
                        <div css={orderReviewText}>{customerRemarks}</div>
                    </Col>
                </Row>
            </OrderReviewSection>
        );
    };

    const {
        lastName, firstName, address1, address2, city, postCode, state, country, email, phoneNumber,
    } = shippingAddress;

    let addressString = address1;
    if (address2) addressString = `${addressString}, ${address2}`;

    return (
        <View>
            <Container fluid css={cartContainer}>
                <CartBreadcrumbs currentItem={2} />
                <Row>
                    <Col xs={12} xl={8}>
                        <Row>
                            <Col>
                                <div css={cartTitle}>Order Review</div>
                            </Col>
                        </Row>

                        <GoldPriceChangeAlert />

                        <Row css={orderReviewSectionTitleContainer}>
                            <Col>
                                <div css={orderReviewSectionTitle}>
                                    Shopping Cart
                                </div>
                            </Col>
                        </Row>
                        <ProductList products={cartItems} />

                        <hr css={{ marginTop: 30, width: '100%', backgroundColor: colors.disabledGray, border: `1px solid ${colors.disabledGray}` }} />

                        <OrderReviewSection
                            title='Shipping Address'
                            onClick={NavActions.navToShippingAddress}
                        >
                            <Row css={orderReviewSection}>
                                <Col>
                                    <div css={orderReviewText}>{`${firstName} ${lastName}`}</div>
                                    <div css={orderReviewText}>
                                        {addressString}
                                    </div>
                                    <div css={orderReviewText}>
                                        {`${city} ${postCode} ${state}, ${country}`}
                                    </div>
                                    <div css={orderReviewText}>{email}</div>
                                    <div css={orderReviewText}>
                                        {phoneNumber}
                                    </div>
                                </Col>
                            </Row>
                        </OrderReviewSection>

                        {renderCustomerRemarks()}
                        {/*
                        <Row>
                            <Col css={orderReviewSectionTitleContainer}>
                                <div css={orderReviewSectionTitle}>
                                    Billing Address
                                </div>
                                <div css={{ cursor: 'pointer' }}>
                                    <Edit />
                                    <span css={orderReviewEditText}>Edit</span>
                                </div>
                            </Col>
                        </Row>
                        <Row css={orderReviewSection}>
                            <Col>
                                {asBilling.value || userInfo.id === '' ? (
                                    <div css={orderReviewText}>
                                        Same as shipping
                                    </div>
                                ) : (
                                    <>
                                        <div css={orderReviewText}>
                                            {`${userInfo.lastName} ${userInfo.firstName}`}
                                        </div>
                                        <div css={orderReviewText}>
                                            {`${userInfo.address?.address1}, ${userInfo.address?.address2}`}
                                        </div>
                                        <div css={orderReviewText}>
                                            {`${userInfo.address?.state} ${userInfo.address?.postCode}, ${userInfo.address?.country}`}
                                        </div>
                                        <div css={orderReviewText}>
                                            {userInfo.email}
                                        </div>
                                        <div css={orderReviewText}>
                                            {userInfo.phoneNumber}
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col css={orderReviewSectionTitleContainer}>
                                <div css={orderReviewSectionTitle}>
                                    Payment Method
                                </div>
                                <div css={{ cursor: 'pointer' }}>
                                    <Edit />
                                    <span css={orderReviewEditText}>Edit</span>
                                </div>
                            </Col>
                        </Row> */}
                        {/* <Row css={orderReviewSection}>
                            <Col>
                                {paymentMethod === 'Bank Transfer' ? (
                                    <>
                                        <div css={orderReviewText}>
                                            {paymentMethod}
                                        </div>
                                    </>
                                ) : (
                                    <Row>
                                        <Col xs='auto'>
                                            <AtmCard />
                                        </Col>
                                        <Col xs='auto'>
                                            <div css={orderReviewText}>
                                                {paymentMethod}
                                            </div>
                                            <div css={orderReviewText}>
                                                {`Card Number: xxxx xxxx xxxx ${cardNumber.slice(12)}`}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row> */}
                    </Col>
                    <Col xs={12} xl={4}>
                        <OrderSummaryReview data={orderSummary} />
                        <div css={orderSummaryContainer}>
                            <Button loading={stockCheckLoading || placeOrderLoading} style={{ marginTop: 30 }} onClick={onProceed} text='PLACE ORDER' disabled={false} />
                            <ErrorMessage
                                error={stockCheckError || placeOrderError}
                                style={{ marginTop: 10, marginBottom: 10 }}
                                textStyle={{ textAlign: 'center' }}
                            />
                            <div css={placeOrderInfo}>
                                {' By clicking Place Order, you agree to our '}
                                <button
                                    type='button'
                                    style={{ backgroundColor: 'transparent', border: 0, textDecorationLine: 'underline' }}
                                    css={{ color: colors.gray }}
                                    onClick={() => NavActions.navToTermsAndConditions()}
                                >
                                    <u>Privacy Policy</u>
                                </button>
                                {' and '}
                                <button
                                    type='button'
                                    style={{ backgroundColor: 'transparent', border: 0, textDecorationLine: 'underline' }}
                                    css={{ color: colors.gray }}
                                    onClick={() => NavActions.navToTermsAndConditions()}
                                >
                                    <u>Terms and Conditions</u>
                                </button>
                                .
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <ModalUnavailableProducts />
        </View>
    );
};

const mapStateToProps = (state) => ({
    cartItems: Selectors.getCartItems(state),
    userInfo: Selectors.getUserInfo(state),
    goldPrice: Selectors.getHomeGoldPrice(state),
    customerRemarks: Selectors.getCartCustomerRemarks(state),
    shippingFee: Selectors.getCartShippingFee(state),
    shippingAddress: Selectors.getCartShippingAddress(state),
    stockCheckLoading: Selectors.getUiStockCheckAttempting(state),
    stockCheckError: Selectors.getUiStockCheckError(state),
    placeOrderLoading: Selectors.getCartPlaceOrderAttempting(state),
    placeOrderError: Selectors.getCartPlaceOrderError(state),
    getCartIsGuest: Selectors.getCartIsGuest(state),
});

const mapDispatchToProps = (dispatch) => ({
    stockCheckAndLock: (products) => dispatch(Actions.uiStockCheckAndLock({ products })),
    clearStockCheckUnavailableProducts: () => dispatch(Actions.uiClearStockCheckUnavailableProducts()),
    placeOrder: (totalPrice, paymentMethod, shippingFee) => dispatch(Actions.cartPlaceOrder({ totalPrice, paymentMethod, shippingFee })),
    placeOrderGuest: (totalPrice, paymentMethod, shippingFee) => dispatch(Actions.cartPlaceOrderGuest({ totalPrice, paymentMethod, shippingFee })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderReview);
