import { css } from '@emotion/react';
import colors from '../../../Themes/colors';
import fonts from '../../../Themes/fonts';

const { white, orange } = colors;

export const cartContainer = css({
    padding: '20px',
    width: '100%',
    height: '100%',

    '@media(min-width: 576px)': {
        padding: '40px',
    },

    '@media(min-width: 1600px)': {
        padding: '40px 120px',
    },
});

export const cartTitle = css({
    ...fonts.cartTitle,
    color: colors.black,
});

export const cartItems = css({
    ...fonts.cartItems,
    color: colors.gray,
});

export const cartOrderSummaryContainer = css({
    padding: '20px',
    backgroundColor: colors.background,
    margin: '40px 0px',

    '@media(min-width:1200px)': {
        margin: '0px',
        padding: '40px',
    },
});

export const cartOrderSummaryTitle = css(cartTitle, {
    color: colors.primary,
});

export const cartOrderSummaryText = css({
    ...fonts.orderSummaryText,
    fontSize: '16px',
    color: colors.black,
    '@media(min-width:576px)': {
        fontSize: '18px',
    },
});

export const cartOrderSummaryColumnRight = css({
    textAlign: 'end',
});

export const cartOrderSummaryRows = css({
    marginTop: '20px',
});

export const promoCodeInput = css({
    ...fonts.productDescriptionText,
    color: colors.gray,
    padding: '10px',
});

export const redeemButton = css({
    ...fonts.productDescriptionText,
    color: colors.white,
    backgroundColor: colors.red,
    padding: '6px 10px',
    borderRadius: '0px',
    border: `1px solid ${colors.red}`,
    '&:hover,&:focus': {
        color: colors.black,
        backgroundColor: colors.white,
        border: `1px solid ${colors.black}`,
    },
});

export const orderTotal = css(cartItems, {
    color: colors.black,
    marginTop: '0px',
    fontSize: '18px',

    '@media(min-width:576px)': {
        fontSize: '20px',
        marginTop: '1.2em',
    },
});

export const editableQuantity = css({
    padding: '12px 15px',
});

export const quantityButton = css({
    backgroundColor: '#F6F7F8',
    color: colors.black,
    border: '0px',
    width: '40px',
    textAlign: 'center',
    '&:hover,&:focus': {
        backgroundColor: colors.primary,
        color: 'unset',
    },
});

export const quantityDropdown = css({
    background: colors.white,
    color: colors.black,
    marginTop: '2px',
});

export const trashButton = css({
    backgroundColor: '#F6F7F8',
    color: colors.black,
    border: '0px',
    height: 40,
    display: 'flex',
    flex: 1,
    textAlign: 'center',
    '&:hover,&:focus': {
        backgroundColor: colors.primary,
        color: 'unset',
    },
});

export const trashIcon = css({
    cursor: 'pointer',
    alignSelf: 'center',
});

export const ProductListItemStyle = css({
    font: '16px Archivo-Regular, sans-serif',
    color: colors.black,
});

export const outOfStockText = css({
    fontFamily: fonts.primary,
    fontSize: 14,
    color: colors.primary,
    marginTop: 10,
});

export const continueShopping = css({
    ...fonts.orderSummaryText,
    color: colors.primary,
    padding: '2px 5px 0px',
});

export const slipContainer = css({
    marginTop: 10,

    '@media(min-width: 450px)': {
        width: 600,
    },
});

export const divider = css({
    height: 1,
    width: '100%',
    backgroundColor: colors.primary,
    marginTop: 20,
});

export const paymentMethodCard = css({
    margin: '15px 5px',
    width: 200,
    height: 90,

    ':hover': {
        WebkitBoxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
    },
});

export const paymentCardLabel = css({
    marginBottom: 0,
    paddingLeft: 30,
    width: '100%',
    height: '100%',
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
});

export const paymentCardLabelSelected = css({
    marginBottom: 0,
    paddingLeft: 30,
    width: '100%',
    height: '100%',
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: colors.lightbrown,
});

export const fpxBankList = css({
    margin: '15px 15px 15px 0px',
    width: 200,
    height: 90,

    ':hover': {
        WebkitBoxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
    },

    '@media(max-width: 450px)': {
        width: '100%',
        height: 'auto',
        margin: '3px 3px 3px 3px',
    },
});

export const fpxBankListColumn = css({
    padding: 0,

    '@media(max-width: 450px)': {
        width: '95%',
    },
});

export const paymentMethodIcon = css({
    color: colors.primary,
    height: 40,
    width: 40,
    margin: '0px 5px',
});

export const bankOfflineLabel = css({
    position: 'absolute',
    right: 1,
    top: 1,
    border: '1px solid transparent',
    backgroundColor: 'rgb(252, 174, 192)',
    borderRadius: 8,
    fontSize: 12,
    width: 'auto',
    padding: 1,
    margin: 1,
});

export const newCustomerTitle = css({
    ...fonts.cartTitle,
    color: colors.white,
});

export const newCustomerText = css({
    ...fonts.productDescriptionText,
    color: colors.white,
});

export const returningCustomerTitle = css({
    ...fonts.cartTitle,
    color: colors.black,
});

export const returningCustomerText = css({
    ...fonts.productDescriptionText,
    color: colors.black,
});

export const checkoutTitle = css({
    font: fonts.tertiary,
    fontWeight: '700',
    color: white,
    marginTop: '20px',
    marginBottom: '20px',
    '@media(min-width: 1200px)': {
        font: '35px Archivo-Regular, serif',
        marginTop: '20px',
        marginBottom: '20px',
    },
});
