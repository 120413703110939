import { JemisysErrorTypes } from '@ddot/AddToCartCommon/lib/useCase';

import productGetProducts from './getProducts';
import productGetProductsFromCategory from './getProductsFromCategory';

export const ErrorTypes = JemisysErrorTypes.ProductErrorTypes;

export default {
    productGetProducts,
    productGetProductsFromCategory,
};
