import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkAPI } from 'Redux/types';

import Selectors from 'Redux/Selectors';
import { IProductWidget } from '@ddot/AddToCartCommon/lib/entities';

export default createAsyncThunk<
    IProductWidget[],
    void,
    AsyncThunkAPI
>('home/getProductWidgets', async (params, thunkAPI) => {
    const state = thunkAPI.getState();

    const productList = Selectors.getProductsList(state);

    if (!productList || !productList.length) return [];

    return [{
        title: 'Best Sellers',
        description: 'Shop our best sellers!',
        products: productList.slice(0, 8),
    }, {
        title: 'New Arrivals',
        description: 'Just released!',
        products: productList.slice(8, 16),
    }] as IProductWidget[];
});
