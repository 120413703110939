import { push, goBack, replace } from 'connected-react-router';

import { store } from 'Redux/store';

const navPush = (routeName: string, state?: any) => {
    store.dispatch(push({ pathname: routeName, state }));
};

const navBack = (): void => {
    store.dispatch(goBack());
};

const navReplace = (routeName: string, params: any) => {
    store.dispatch(replace({ pathname: routeName, state: params }));
};

const navReset = (stackName: string) => {
    store.dispatch(replace(stackName));
};

const navToHome = (): void => navPush('/');
const navResetToHome = (): void => navReset('/');

const navToAboutUs = (): void => navPush('/about-us');
const navToTermsAndConditions = (): void => navPush('/termsandconditions');

const navToProductListing = (): void => navPush('/categories');
const navToProductListingWithCategory = (id: string): void => navPush(`/categories/${id}`);
const navToProductSearch = (search: string): void => navPush(`/search/${search}`);

const navToProductDetails = (productId = ''): void => navPush(`/product/${productId}`);

const navToCart = (): void => navPush('/cart');
const navToSubmitCyberSourceInfo = (): void => navPush('/submitCyberSourceInfo');
const navToSubmitFpxInfo = (): void => navPush('/submitFpxInfo');

const navToShippingAddress = (): void => navPush('/shipping');
const navToOrderReview = (): void => navPush('/order');
const navToPayment = (): void => navPush('/payment');
const navToOrderConfirmation = (): void => navPush('/orderconfirmation');

const navToUserProfile = (): void => navPush('/user');
const navToWishlist = (): void => navPush('/wishlist');

const navToError = (): void => navReset('/error');
const navResetToMaintenance = (): void => navReset('/maintenance');

export default {
    navBack,

    navToHome,
    navResetToHome,

    navToAboutUs,
    navToTermsAndConditions,

    navToProductListing,
    navToProductListingWithCategory,
    navToProductSearch,
    navToProductDetails,

    navToCart,
    navToSubmitCyberSourceInfo,
    navToSubmitFpxInfo,

    navToShippingAddress,
    navToOrderReview,
    navToPayment,
    navToOrderConfirmation,

    navToUserProfile,
    navToWishlist,

    navToError,
    navResetToMaintenance,
};
