import { IProduct, IProductWidget } from '@ddot/AddToCartCommon/lib/entities';
import { IHome, IGoldPrice } from './Entities';

const getGoldPriceAttempting = (state: IHome): boolean => state.actions.goldPrice;
const getGoldPrice = (state: IHome): IGoldPrice => state.goldPrice;

const getIsMaintenance = (state: IHome): boolean => state.isMaintenance;

const getFeaturedProductsAttempting = (state: IHome): boolean => state.actions.featuredProducts || false;
const getFeaturedProductsError = (state: IHome): string => state.error.featuredProducts || '';
const getFeaturedProducts = (state: IHome): IProduct[] => state.featuredProducts || [];

const getProductWidgetAttempting = (state: IHome): boolean => state.actions.productWidgets;
const getProductWidget = (state: IHome): IProductWidget[] | undefined => state.productWidgets;

export default {
    getGoldPriceAttempting,
    getGoldPrice,

    getIsMaintenance,

    getFeaturedProductsAttempting,
    getFeaturedProductsError,
    getFeaturedProducts,

    getProductWidgetAttempting,
    getProductWidget,
};
