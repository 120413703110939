/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Pressable } from 'react-native-web';
import { connect } from 'react-redux';

import NavActions from 'Services/Navigation/Actions';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';

import { RootState } from 'Redux/store';

import { IProduct } from '@ddot/AddToCartCommon/lib/entities/Product';
import { IProductCategory } from '@ddot/AddToCartCommon/lib/entities/ProductCategory';
import FeaturedProduct from './FeaturedProduct';
import {
    productsRowContainer,
    viewMoreCollection,
    viewMoreContainer,
    viewMoreText,
} from '../Styles/ProductsRowStyles';

interface ProductsProps {
    featuredProducts: IProduct[];
    categories: IProductCategory[];
    getFeaturedProducts(): void;
}

const ProductsRow = (props: ProductsProps): JSX.Element | null => {
    const { featuredProducts, categories } = props;

    useEffect(() => {
        const { getFeaturedProducts } = props;
        getFeaturedProducts();
    }, []);

    if (!featuredProducts || !featuredProducts.length) return null;
    if (!categories) return null;

    const category = categories.find(item => item.id === featuredProducts[0].categoryId);
    if (!category) return null;

    const navToCategory = (item: IProductCategory) => {
        NavActions.navToProductListingWithCategory(item.id);

        window.scrollTo(0, 0);
    };

    const products = featuredProducts.map((product) => {
        return (
            <Col xs={6} key={product.id + product.name} className='p-0'>
                <FeaturedProduct product={product} />
            </Col>
        );
    });

    return (
        <Container fluid css={productsRowContainer}>
            <Row xs={1} sm={4} md={5}>
                {products}
                <Col css={viewMoreContainer} className='d-none d-md-flex'>
                    <Pressable onPress={() => navToCategory(category)}>
                        <div css={viewMoreText}>View all</div>
                    </Pressable>
                    <div css={viewMoreCollection}>{category.name}</div>
                </Col>
            </Row>
            <Row xs={12} className='d-block d-md-none'>
                <Col css={viewMoreContainer}>
                    <Pressable onPress={() => navToCategory(category)}>
                        <div css={viewMoreText}>View all</div>
                    </Pressable>
                    <div css={viewMoreCollection}>{category.name}</div>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    featuredProducts: Selectors.getHomeFeaturedProducts(state),
    categories: Selectors.getProductsCategories(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getFeaturedProducts: () => dispatch(Actions.homeGetFeaturedProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsRow);
