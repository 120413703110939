import { IProduct, IProductCategory, IProductCollection } from '@ddot/AddToCartCommon/lib/entities';
import { IProducts as IProductState, IProductDetails } from './Entities';

const getProductsAttempting = (state: IProductState): boolean => state.actions.productList || false;
const getProductsError = (state: IProductState): string => state.error.productList || '';
const getProductsList = (state: IProductState): IProduct[] => state.productList;

const getDetailsAttempting = (state: IProductState): boolean => state.actions.productDetails || false;
const getDetailsError = (state: IProductState): string => state.error.productDetails || '';
const getDetails = (state: IProductState): IProductDetails | null => state.productDetails || null;

const getCollectionsAttempting = (state: IProductState): boolean => state.actions.collections || false;
const getCollectionsError = (state: IProductState): string => state.error.collections || '';
const getCollections = (state: IProductState): IProductCollection[] => state.collection || [];

const getCategoriesAttempting = (state: IProductState): boolean => state.actions.categories || false;
const getCategoriesError = (state: IProductState): string => state.error.categories || '';
const getCategories = (state: IProductState): IProductCategory[] => state.category || [];

export default {
    getProductsAttempting,
    getProductsError,
    getProductsList,

    getDetailsAttempting,
    getDetailsError,
    getDetails,

    getCollectionsAttempting,
    getCollectionsError,
    getCollections,

    getCategoriesAttempting,
    getCategoriesError,
    getCategories,
};
