import { ICartProduct, IFpxBankList, IShippingAddress } from '@ddot/AddToCartCommon/lib/entities';
import { ICart as ICartState, ICyberSourceInfo } from './Entities';

const getCartAttempting = (state: ICartState): boolean => state.actions.cart || false;
const getCartError = (state: ICartState): string => state.error.cart || '';
const getCartId = (state: ICartState): string | null => state.cartId;
const getCartItems = (state: ICartState): ICartProduct[] => state.products;

const getCartIsGuest = (state: ICartState): boolean => state.isGuest;

const getAddToCartAttempting = (state: ICartState): boolean => state.actions.addToCart || false;
const getAddToCartError = (state: ICartState): string => state.error.addToCart || '';

const getShippingFee = (state: ICartState): number => state.shippingFee || 0;
const getShippingAddress = (state: ICartState): IShippingAddress | undefined => state.shippingAddress;
const getTotalItemPrice = (state: ICartState): number => state.totalItemPrice || 0;
const getCurrency = (state: ICartState): string => state.currency || '';
const getPromoCode = (state: ICartState): string => state.promoCode || '';
const getRemarks = (state: ICartState): string => state.customerRemarks || '';

const getUpdateQuantityAttempting = (state: ICartState): boolean => state.actions.updateCartQuantity || false;
const getUpdateQuantityError = (state: ICartState): string => state.error.updateCartQuantity || '';

const getPlaceOrderAttempting = (state: ICartState): boolean => state.actions.placeOrder;
const getPlaceOrderError = (state: ICartState): string => state.error.placeOrder;

const getFpxBankListAttempting = (state: ICartState): boolean => state.actions.fpxBankList || false;
const getFpxBankListError = (state: ICartState): string => state.error.fpxBankList || '';
const getFpxBankList = (state: ICartState): IFpxBankList[] => state.fpxBankList || [];

const getCyberSourceInfoAttempting = (state: ICartState): boolean => state.actions.cyberSourceInfo || false;
const getCyberSourceInfoError = (state: ICartState): string => state.error.cyberSourceInfo || '';
const getCyberSourceInfo = (state: ICartState): ICyberSourceInfo => state.cyberSourceInfo;

export default {
    getCartAttempting,
    getCartError,
    getCartId,
    getCartItems,

    getCartIsGuest,

    getAddToCartAttempting,
    getAddToCartError,

    getShippingFee,
    getShippingAddress,
    getTotalItemPrice,
    getCurrency,
    getPromoCode,
    getRemarks,

    getUpdateQuantityAttempting,
    getUpdateQuantityError,

    getPlaceOrderAttempting,
    getPlaceOrderError,

    getFpxBankListAttempting,
    getFpxBankListError,
    getFpxBankList,

    getCyberSourceInfoAttempting,
    getCyberSourceInfoError,
    getCyberSourceInfo,
};
