/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

import { Back } from 'Icons/Icons';
import TableSkeleton from 'Components/Skeletons/TableSkeleton';
import ErrorMessage from 'Components/Common/ErrorMessage';

import Utils from 'Lib/Utils';
import Toast from 'Lib/Toast';
import NavActions from 'Services/Navigation/Actions';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import { IOrder } from '@ddot/AddToCartCommon/lib/entities';
import OrderHistoryProduct from './OrderHistoryProduct';
import AddressComponent from './OrderAddress';
import OrderSummary from './OrderSummary';
import OrderId from './OrderId';

import {
    // ButtonStyle5,
    OrderStyle,
    OrderStyleBold,
    BackandProgContainer,
    BackButton,
    ProgressStatus,
    ProgressStatusGreen,
    // ProgressStatusRed,
    BackandSymbol,
    BackCenterer,
    RowFlexCenter,
    ProductListTitleStyle,
    Title,
} from '../Styles/ComponentStyles';

interface OrderInfoProps {
    orderItem: IOrder;
    setIsDetails(isDetails: boolean): void;
    getOrderHistoryDetails(order: IOrder): void;
    loading: boolean;
    error: string;
    orderHistory: IOrder[];
}

const OrderInfo = (props: OrderInfoProps): JSX.Element | null => {
    const { orderItem, setIsDetails, orderHistory } = props;

    const selectedItem = orderHistory.find(item => item.id === orderItem.id);

    if (!selectedItem) {
        Toast.showError('An error occurred. Please try again.');
        NavActions.navBack();
        return null;
    }

    const { products, shippingFee = 0 } = selectedItem;

    useEffect(() => {
        const { getOrderHistoryDetails } = props;

        if (!products.length) getOrderHistoryDetails(orderItem);
    }, []);

    const {
        id,
        orderDate,
        totalPrice,
        currency,
        deliveryInfo,
        paymentMethod,
        status,
        customAttributes,
    } = orderItem;

    let mappedPaymentMethod: string;

    if (paymentMethod === 1) {
        mappedPaymentMethod = 'Online Banking';
    } else if (paymentMethod === 2) {
        mappedPaymentMethod = 'Credit Cart';
    } else if (paymentMethod === 3) {
        mappedPaymentMethod = 'Bank Transfer';
    }

    const renderAddress = () => {
        if (!deliveryInfo) return null;

        const { address } = deliveryInfo;
        const { email, phoneNumber, firstName, lastName, address1, address2, city, postCode, state, country } = address;

        let addressLine1 = address1;
        if (address2) addressLine1 += `, ${address2}`;
        if (city) addressLine1 += `, ${city}`;

        let addressLine2 = postCode;
        if (state) addressLine2 += `, ${state}`;
        if (country) addressLine2 += `, ${country}`;

        return (
            <AddressComponent
                name={`${firstName} ${lastName}`}
                address1={addressLine1}
                address2={addressLine2}
                email={email}
                phoneNumber={phoneNumber}
                billingAddress='Same as shipping'
                paymentMethod={mappedPaymentMethod}
            />
        );
    };

    const renderStatus = () => {
        if (!status) return null;

        if (status === 3) {
            return (
                <div
                    className='text-nowrap'
                    css={css`
                        ${ProgressStatus};
                        ${ProgressStatusGreen}
                    `}
                >
                    In Progress
                </div>
            );
        }
        if (status === 4) {
            return (
                <div
                    className='text-nowrap'
                    css={css`
                        ${ProgressStatus};
                        ${ProgressStatusGreen}
                    `}
                >
                    Shipped
                </div>
            );
        }

        return (
            <div
                className='ml-3 mr-4 pl-4 pr-4 p-2 text-nowrap'
                css={css`
                    ${ProgressStatus};
                    ${ProgressStatusGreen}
                `}
            >
                Shipped
            </div>
        );
    };

    const renderProducts = () => {
        const { loading, error } = props;

        if (loading) return <TableSkeleton />;
        if (error) return <ErrorMessage error={error} />;

        return (
            <div>
                <div className='mt-5' css={Title}>
                    Order Review
                </div>
                <Row className='mt-4 d-none d-md-flex'>
                    <Col xs='5'>
                        <div css={ProductListTitleStyle}>Product Details</div>
                    </Col>
                    <Col xs='2' css={RowFlexCenter}>
                        <div css={ProductListTitleStyle}>Quantity</div>
                    </Col>
                    <Col xs='2' css={RowFlexCenter}>
                        <div css={ProductListTitleStyle}>Price</div>
                    </Col>
                    {/* <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListTitleStyle}>Labour Cost</div> */}
                    {/* </Col> */}
                    <Col xs='2' css={RowFlexCenter}>
                        <div css={ProductListTitleStyle}>Total</div>
                    </Col>
                </Row>
                {products.map((product) => (
                    <OrderHistoryProduct key={product.id} product={product} />
                ))}
            </div>
        );
    };

    const renderOrderNo = () => {
        if (!customAttributes) return null;

        const orderNo = Utils.CustomAttr.getValueFromKey('OrderNo', customAttributes) as string;

        if (!orderNo) return null;

        return (
            <div>
                <span css={OrderStyleBold}>Order Number: </span>
                <span css={OrderStyle}>{orderNo}</span>
            </div>
        );
    };

    const renderShippingNo = () => {
        if (!deliveryInfo || !deliveryInfo.customAttributes) return null;

        const shippingNo = Utils.CustomAttr.getValueFromKey('ShippingNo', deliveryInfo.customAttributes) as string;

        if (!shippingNo) return null;

        return (
            <div>
                <span css={OrderStyleBold}>Invoice Number: </span>
                <span css={OrderStyle}>{shippingNo}</span>
            </div>
        );
    };

    return (
        <div>
            <div className='pt-3' css={BackandProgContainer}>
                <div css={BackCenterer}>
                    <span
                        css={BackandSymbol}
                        onClick={() => setIsDetails(false)}
                        onKeyPress={() => setIsDetails(false)}
                        role='button'
                        tabIndex={0}
                    >
                        <Back className='mr-2' />
                        <div css={BackButton}>Back</div>
                    </span>
                </div>
                {renderStatus()}
            </div>

            <div className='mt-4'>
                <OrderId orderId={id} />
                {renderOrderNo()}
                {renderShippingNo()}
                <div>
                    <span css={OrderStyleBold}>Order Date : </span>
                    <span css={OrderStyle}>{orderDate}</span>
                </div>
                {/* <TrackingNShippingComponent
                    LogisticsCompany='MY4983822'
                    trackingNumber='Poslaju'
                /> */}
                {/* <div>
                    <Button className='mt-3' css={ButtonStyle5}>
                        View Detail
                    </Button>
                </div> */}
                {renderAddress()}
                {renderProducts()}
                <OrderSummary
                    ShippingCost={shippingFee}
                    SubTotal={totalPrice}
                    currency={currency}
                />
            </div>
        </div>
    );
};

// export function TrackingNShippingComponent({
//     trackingNumber,
//     LogisticsCompany,
// }) {
//     return (
//         <div>
//             <div className='mt-2'>
//                 <span css={OrderStyleBold}>Tracking Number : </span>
//                 <span css={OrderStyle}>{trackingNumber}</span>
//             </div>
//             <div className='mt-2'>
//                 <span css={OrderStyleBold}>Logistic Company : </span>
//                 <span css={OrderStyle}>{LogisticsCompany}</span>
//             </div>
//         </div>
//     );
// }

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getUserOrderHistoryDetailsAttempting(state),
    error: Selectors.getUserOrderHistoryDetailsError(state),
    orderHistory: Selectors.getUserOrderHistory(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getOrderHistoryDetails: (order: IOrder) => dispatch(Actions.userGetOrderHistoryDetails(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfo);
