import React, { useEffect } from 'react';
import { View } from 'react-native-web';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import ErrorMessage from 'Components/Common/ErrorMessage';
import PageSkeleton from 'Components/Skeletons/PageSkeleton';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import { IProduct, IProductCategory } from '@ddot/AddToCartCommon/lib/entities';
import Banner from './Components/Banner';
import Listing from './Components/Listing';

interface ListingProps extends RouteComponentProps<any> {
    loading: boolean;
    error: string;
    products: IProduct[];
    getProducts(): void;
    setCategory(cat: IProductCategory | null): void;
    searchProduct(query: string): void;
    resetProductsList(): void;
    searchingProducts: boolean;
}

const ProductListing = (props: ListingProps) => {
    const {
        loading, products, getProducts, error, searchingProducts, searchProduct,
    } = props;
    const { match, setCategory } = props;
    const { categoryId, query } = match.params;

    useEffect(() => {
        if (!products) getProducts();

        return () => {
            const { resetProductsList } = props;

            resetProductsList();
        };
    }, []);

    useEffect(() => {
        if (categoryId) {
            setCategory({
                id: categoryId,
                name: categoryId,
            });
        }

        if (query) {
            searchProduct(query);
        }

        if (!categoryId && !query) setCategory(null);
    }, [categoryId, query, products]);

    if (loading || searchingProducts) return <PageSkeleton />;
    if (error) return <ErrorMessage error={error} />;

    return (
        <View>
            <Banner />
            <Listing categoryId={categoryId} />
        </View>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getProductsListAttempting(state),
    error: Selectors.getProductsListError(state),
    products: Selectors.getProductsList(state),
    searchingProducts: Selectors.getUiSearchProductsAttempting(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getProducts: () => dispatch(Actions.productGetProducts()),
    setCategory: (category: IProductCategory | null) => dispatch(Actions.productGetProductsFromCategory({ category })),
    searchProduct: (query: string | '') => dispatch(Actions.uiSearchProducts({ query })),
    resetProductsList: () => dispatch(Actions.uiResetProductList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
