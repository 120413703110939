import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkAPI } from 'Redux/types';
import Selectors from 'Redux/Selectors';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { IOrder } from '@ddot/AddToCartCommon/lib/entities';
import { ErrorTypes } from '.';

export default createAsyncThunk<
    IOrder[],
    void,
    AsyncThunkAPI
>('user/getOrderHistory', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const email = Selectors.getUserEmail(state);

    if (email) {
        const response = await thunkAPI.extra.useCases.user.getOrderHistory.execute({ email });

        if (response.status === UseCaseResponseStatus.Success) {
            return response.data;
        }
        if (response.data.type === ErrorTypes.GetOrderHistoryError.MissingEmail) {
            return thunkAPI.rejectWithValue(response.data.title || 'Email missing!');
        }
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
    } return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
