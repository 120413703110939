import { createAsyncThunk } from '@reduxjs/toolkit';

import uiSlice from 'Redux/slices/ui';
import { AsyncThunkAPI } from 'Redux/types';
import Selectors from 'Redux/Selectors';

import Toast from 'Lib/Toast';

import { IUser } from '@ddot/AddToCartCommon/lib/entities';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { ErrorTypes } from '.';

interface UpdateUserInfoThunkParams {
    idNo: string;
    salutation: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
    birthday: Date;
    gender: 'MALE' | 'FEMALE';
    email: string;
    password: string;
    phoneNumber: string;
}

export default createAsyncThunk<
    IUser,
    UpdateUserInfoThunkParams,
    AsyncThunkAPI
>('user/updateUserInfo', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const userId = Selectors.getUserId(state);
    const authToken = Selectors.getAuthAuthToken(state);

    if (!authToken || !userId) {
        return thunkAPI.rejectWithValue('Something went wrong. Please try again later.');
    }

    const {
        address1, address2, city, country, postCode, state: userState, firstName, email, lastName, phoneNumber,
    } = params;

    const userInfo: IUser = {
        id: userId,
        address: {
            address1,
            address2,
            city,
            country,
            postCode,
            state: userState,
        },
        firstName,
        lastName,
        email,
        phoneNumber,
    };

    const response = await thunkAPI.extra.useCases.user.updateUserInfo.execute({
        authToken,
        userInfo,
    });

    if (response.status === UseCaseResponseStatus.Success) {
        Toast.show('Profile updated!');
        thunkAPI.dispatch(uiSlice.actions.uiSetProfileIsEditing(false));

        return userInfo;
    }

    const { type, title } = response.data;

    if (type === ErrorTypes.UpdateUserInfoError.InvalidFields) {
        return thunkAPI.rejectWithValue('Invalid fields. Please try again.');
    }

    if (type === ErrorTypes.UpdateUserInfoError.MissingFields) {
        return thunkAPI.rejectWithValue('Missing input. Please try again.');
    }

    return thunkAPI.rejectWithValue(title || 'Something went wrong. Please try again later.');
});
