import { createAsyncThunk } from '@reduxjs/toolkit';
import Selectors from 'Redux/Selectors';

import { AsyncThunkAPI } from 'Redux/types';

import uiSlice from 'Redux/slices/ui';
import Utils from 'Lib/Utils';

import { ICartProduct } from '@ddot/AddToCartCommon/lib/entities';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { ErrorTypes } from '.';

export interface GetCartThunkResponse {
    cartId: string | null;
    products: ICartProduct[];
}

export default createAsyncThunk<
    GetCartThunkResponse, // this is the response type
    void, // this is the param type
    AsyncThunkAPI // will be always the same
>('cart/getCart', async (params, thunkAPI) => {
    const state = thunkAPI.getState();

    const productList = Selectors.getProductsList(state);
    const userId = Selectors.getUserId(state);
    const goldPrice = Selectors.getHomeGoldPrice(state);

    const response = await thunkAPI.extra.useCases.cart.getCartLocalWeb.execute({ userId, productList });

    if (response.status === UseCaseResponseStatus.Success) {
        const { cart, status } = response.data;
        const { cartId, products } = cart;

        const parsedProducts = products.map((item: ICartProduct) => {
            const itemPrice = Utils.Jemisys.getCartItemPrice(item, goldPrice);
            return { ...item, price: itemPrice };
        });

        if (status === 'ItemNoLongerAvailable') {
            thunkAPI.dispatch(uiSlice.actions.uiSetCartStockUnavailable(true));
        }

        return {
            cartId,
            products: parsedProducts,
        };
    }

    if (response.data.type === ErrorTypes.GetCartLocalWebError.SystemError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
    }

    if (response.data.type === ErrorTypes.GetCartLocalWebError.NoCartFound) {
        return {
            cartId: '',
            products: [],
        };
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
