import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import Atc from 'Lib/ATC';

import authReducer from './slices/auth';
import homeReducer from './slices/home';
import productReducer from './slices/products';
import cartReducer from './slices/cart';
import userReducer from './slices/user';
import uiReducer from './slices/ui';

export const history = createBrowserHistory();

const useCases = Atc.UseCases.Cases;

export const rootReducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer.reducers,
    home: homeReducer.reducers,
    products: productReducer.reducers,
    cart: cartReducer.reducers,
    user: userReducer.reducers,
    ui: uiReducer.reducers,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: { useCases },
            },
        }).concat(routerMiddleware(history)),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
