/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TableSkeleton from 'Components/Skeletons/TableSkeleton';
import ErrorMessage from 'Components/Common/ErrorMessage';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import { IOrder } from '@ddot/AddToCartCommon/lib/entities';
import OrderHistoryItem from '../Components/OrderHistoryItem';
import OrderInfo from '../Components/OrderInfo';
import TabsComponent from '../Components/TabsComponent';
import { OrdersContainer, orderEmpty } from '../Styles/OrderHistoryStyles';

interface OrderProps {
    loading: boolean;
    error: string;
    orders: IOrder[];
    getOrderHistory(): void;
}

const OrderHistory = (props: OrderProps): JSX.Element => {
    const TAB_OPTIONS = ['INPROGRESS', 'SHIPPED'];
    //  tab selection

    const { loading, error, orders } = props;

    const [orderHistory, setOrderHistory] = useState<IOrder[]>([]);
    const [selectedTab, setSelectedTab] = useState(TAB_OPTIONS[0]);
    const [isDetails, setIsDetails] = useState(false);
    const [orderItemSelected, setorderItemSelected] = useState<IOrder | null>(null);

    useEffect(() => {
        const { getOrderHistory } = props;
        getOrderHistory();
    }, []);

    useEffect(() => {
        setOrderHistory(
            orders.filter((historyItem) => {
                return historyItem.status === (selectedTab === 'INPROGRESS' ? 3 : 4);
            }),
        );
    }, [selectedTab, orders]);

    const renderBody = () => {
        if (loading) return <TableSkeleton />;
        if (error) return <ErrorMessage error={error} style={{ marginTop: 20 }} textStyle={{ textAlign: 'left' }} />;

        if (isDetails && orderItemSelected) {
            return <OrderInfo orderItem={orderItemSelected} setIsDetails={setIsDetails} />;
        }

        if (!orderHistory.length) {
            return (
                <div css={orderEmpty}>
                    There is nothing here. Place an order now!
                </div>
            );
        }

        return orderHistory.map((orderHistoryObject) => (
            <OrderHistoryItem
                key={orderHistoryObject.id}
                orderHistoryObject={orderHistoryObject}
                setIsDetails={setIsDetails}
                setorderItemSelected={setorderItemSelected}
            />
        ));
    };

    return (
        <div css={OrdersContainer}>
            {!isDetails && (
                <TabsComponent
                    selectedTab={selectedTab}
                    TAB_OPTIONS={TAB_OPTIONS}
                    setSelectedTab={setSelectedTab}
                />
            )}
            <div>
                {renderBody()}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getUserOrderHistoryAttempting(state),
    error: Selectors.getUserOrderHistoryError(state),
    orders: Selectors.getUserOrderHistory(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getOrderHistory: () => dispatch(Actions.userGetOrderHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
