import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkAPI } from 'Redux/types';
import Selectors from 'Redux/Selectors';

import Toast from 'Lib/Toast';
import { IProduct } from '@ddot/AddToCartCommon/lib/entities';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';

interface RemoveFromWishlistThunkParams {
    product: IProduct;
}

export default createAsyncThunk<
    string, // productId
    RemoveFromWishlistThunkParams,
    AsyncThunkAPI
>('user/removeFromWishlistLocalWeb', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const { product } = params;
    const userId = Selectors.getUserId(state);

    const response = await thunkAPI.extra.useCases.user.removeFromWishlistLocalWeb.execute({ userId, product });

    if (response.status !== UseCaseResponseStatus.Success) {
        const errorMessage = response.data.message || 'Something went wrong, please try again later.';

        Toast.showError(errorMessage);
        return thunkAPI.rejectWithValue(errorMessage);
    }

    Toast.show('Item removed');
    return product.id;
});
