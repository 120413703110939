/** @jsxImportSource @emotion/react */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { View, Text, Modal, StyleSheet, ScrollView } from 'react-native-web';
import { connect } from 'react-redux';
import { css } from '@emotion/react';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';

import Colors from 'Themes/colors';
import Fonts from 'Themes/fonts';
import Button from 'Components/Button';
import { RootState } from 'Redux/store';
import { ICartProduct } from '@ddot/AddToCartCommon/lib/entities/Cart';

interface UnavailableProductsProps {
    products: ICartProduct[];
    clearStockCheckUnavailableProducts(): void;
    removeFromCart(product: ICartProduct): void;
}

const ModalUnavailableProducts = (props: UnavailableProductsProps): JSX.Element | null => {
    const {
        products, clearStockCheckUnavailableProducts,
    } = props;

    if (!products.length) return null;
    const groupedStockUnavailable: any[] = [];
    products?.forEach((product) => {
        const p = groupedStockUnavailable.find((gsu) => gsu.id === product.id);
        if (p) {
            p.quantity += 1;
        } else {
            groupedStockUnavailable.push({
                id: product.id,
                name: product.name,
                quantity: 1,
            });
        }
    });

    const onBack = () => {
        clearStockCheckUnavailableProducts();
    };

    const onContinue = () => {
        const { removeFromCart } = props;

        products.forEach((product) => {
            removeFromCart(product);
        });

        clearStockCheckUnavailableProducts();
    };

    return (
        <Modal visible transparent>
            <View style={styles.container}>
                <Text style={styles.header}>
                    You have a few items that are out of stock!
                </Text>
                <Text style={styles.text}>
                    If you proceed, these items will be removed from your cart.
                </Text>
                <ScrollView style={styles.unavailableItemsScroller}>
                    <Row css={unavailableItemsContainerHeader}>
                        <Col xs='9'>Name</Col>
                        <Col xs='3'>Quantity</Col>
                    </Row>
                    {groupedStockUnavailable?.map((product) => (
                        <Row key={product.id} css={unavailableItemsContainer}>
                            <Col xs='9'>{product.name}</Col>
                            <Col xs='3'>
                                x
                                {product.quantity}
                            </Col>
                        </Row>
                    ))}
                </ScrollView>
                <Row xs='12' css={buttonStyles}>
                    <Col xs='6'>
                        <Button text='Cancel' style={{ margin: 10, padding: 10 }} onClick={onBack} />
                    </Col>
                    <Col xs='6'>
                        <Button text='Continue' style={{ margin: 10, padding: 10 }} onClick={onContinue} />
                    </Col>
                </Row>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: '3%',
        backgroundImage: `linear-gradient(to bottom, ${Colors.mildbrown}, ${Colors.white})`,
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto',
        width: 470,
        borderColor: Colors.border,
        borderWidth: 1,
        borderRadius: 10,
        margin: 'auto',
    },
    header: {
        fontFamily: Fonts.primary,
        textAlign: 'center',
        fontSize: '20px',
        margin: 5,
    },
    text: {
        fontFamily: Fonts.primary,
        textAlign: 'center',
        fontSize: '11px',
    },
    backButton: {
        marginTop: 10,
    },
    proceedButton: {
        marginTop: 10,
    },
    unavailableItemsScroller: {
        width: 370,
        height: 300,
        margin: 10,
        borderWidth: 1,
        borderColor: Colors.border,
        backgroundColor: Colors.white,
        borderRadius: 10,
    },
});
const buttonStyles = css({
    justifyContent: 'space-between',
    flexDirection: 'row',
});

const unavailableItemsContainerHeader = css({
    backgroundColor: Colors.primary,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    color: Colors.white,
    padding: '10px 30px 10px 30px',
});

const unavailableItemsContainer = css({
    backgroundColor: Colors.white,
    borderTop: '1px dashed grey',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '10px 30px 10px 30px',
});

const mapStateToProps = (state: RootState) => ({
    products: Selectors.getUiStockCheckUnavailableProduct(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    removeFromCart: (product: ICartProduct) => dispatch(Actions.cartRemoveFromCart({ product })),
    clearStockCheckUnavailableProducts: () => dispatch(Actions.uiClearStockCheckUnavailableProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUnavailableProducts);
