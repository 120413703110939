import { createAsyncThunk } from '@reduxjs/toolkit';

import Toast from 'Lib/Toast';
import Utils from 'Lib/Utils';

import Selectors from 'Redux/Selectors';

import { AsyncThunkAPI } from 'Redux/types';

import { ICartProduct, IProduct, IProductVariant } from '@ddot/AddToCartCommon/lib/entities';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { ErrorTypes } from '.';

export interface IAddToCartThunkParams {
    product: IProduct;
    selectedVariant?: IProductVariant;
    quantity: number;
    remarks?: string;
}

export interface IAddToCartThunkResponse {
    cartId: string;
    cartItems: ICartProduct[];
}

export default createAsyncThunk<
    IAddToCartThunkResponse, // this is the response type
    IAddToCartThunkParams, // this is the param type
    AsyncThunkAPI // will be always the same
>('cart/addToCart', async (params, thunkAPI) => {
    const state = thunkAPI.getState();

    const { product, selectedVariant, quantity, remarks } = params;

    const salesType = Utils.Product.getSalesType(product.customAttributes);

    if (salesType === 'GOLD') {
        if (!selectedVariant || quantity <= 0) {
            return thunkAPI.rejectWithValue('Please select a variant.');
        }
    } else if (salesType === 'JEWELERY') {
        if (quantity <= 0) {
            return thunkAPI.rejectWithValue('Please select at least one quantity.');
        }
    } else {
        return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
    }

    const userId = Selectors.getUserId(state);

    const itemToAdd: ICartProduct = {
        ...product,
        // if this is a jewellery item, we will random assign a selected variant for now
        selectedVariant: selectedVariant || product.variants[0],
        quantity,
        remarks,
    };

    const response = await thunkAPI.extra.useCases.cart.addToCartLocalWeb.execute({ userId, product: itemToAdd });

    if (response.status === UseCaseResponseStatus.Success) {
        const { cartId, cartItems } = response.data;

        Toast.show('Item added to cart!');

        return {
            cartId,
            cartItems,
        };
    }

    const { type, message, title } = response.data;

    if (type === ErrorTypes.AddToCartLocalWebError.SystemError) {
        return thunkAPI.rejectWithValue(message || 'Something went wrong. Please try again.');
    }

    if (type === ErrorTypes.AddToCartLocalWebError.MaxQuantityReached) {
        return thunkAPI.rejectWithValue('Max quantity already added to cart.');
    }

    if (type === ErrorTypes.AddToCartLocalWebError.InvalidProduct) {
        return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
    }

    return thunkAPI.rejectWithValue(title || 'Something went wrong. Please try again.');
});
