import { createAsyncThunk } from '@reduxjs/toolkit';

import Selectors from 'Redux/Selectors';
import { AsyncThunkAPI } from 'Redux/types';

import Utils from 'Lib/Utils';

import { ICartProduct } from '@ddot/AddToCartCommon/lib/entities';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { ErrorTypes } from '.';

interface UpdateCartQuantityThunkParams {
    product: ICartProduct;
    newQuantity: number;
}

export interface UpdateCartQuantityThunkResponse {
    updatedProduct: ICartProduct;
}

export default createAsyncThunk<
    UpdateCartQuantityThunkResponse, // we'll return the entire cart productList here
    UpdateCartQuantityThunkParams,
    AsyncThunkAPI
>('cart/updateCartQuantity', async (params, thunkAPI) => {
    const state = thunkAPI.getState();

    const { product, newQuantity } = params;

    const userId = Selectors.getUserId(state);
    const goldPrice = Selectors.getHomeGoldPrice(state);

    const response = await thunkAPI.extra.useCases.cart.updateCartQuantityLocalWeb.execute({
        product,
        newQuantity,
        userId,
    });

    if (response.status === UseCaseResponseStatus.Success) {
        const price = Utils.Jemisys.getCartItemPrice(response.data, goldPrice);

        return {
            updatedProduct: { ...response.data, price },
        };
    }

    const { type, title } = response.data;

    if (type === ErrorTypes.UpdateCartQuantityLocalWebError.InvalidQuantity) {
        return thunkAPI.rejectWithValue('Invalid quantity. Please try again.');
    }

    if (type === ErrorTypes.UpdateCartQuantityLocalWebError.MissingProduct) {
        return thunkAPI.rejectWithValue('Product does not exist. Please add the product first.');
    }

    if (type === ErrorTypes.UpdateCartQuantityLocalWebError.NoCartFound) {
        return thunkAPI.rejectWithValue('Product does not exist. Please add the product first.');
    }

    return thunkAPI.rejectWithValue(title || 'Something went wrong. Please try again.');
});
