/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Row, Label, CustomInput, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { RootState } from 'Redux/store';
import Selectors from 'Redux/Selectors';
import Actions from 'Redux/Actions';

import { IProduct } from '@ddot/AddToCartCommon/lib/entities';
import { productListingOptionsIcon, sortDropdownToggle, filterDropdownMenu } from '../Styles/ProductListingStyles';

interface ListingProps {
    uiProductList: IProduct[];
    productList: IProduct[];
    filterProducts(filters: filterParams): void;
    categoryId: string;
}

const Filter = (props: ListingProps): JSX.Element => {
    const { filterProducts, categoryId, uiProductList, productList } = props;
    const [isOpen, setIsOpen] = useState(false);

    const [purityRange, setPurityRange] = useState<any[]>([]);
    const [weightRange, setWeightRange] = useState<number[]>([]);
    const [lengthRange, setLengthRange] = useState<number[]>([]);
    const [sizeRange, setSizeRange] = useState<number[]>([]);

    const [purity, setPurity] = useState<any[]>([]);
    const [weight, setWeight] = useState<number[]>([]);
    const [length, setLength] = useState<number[]>([]);
    const [size, setSize] = useState<number[]>([]);

    useEffect(() => {
        setPurity([]);
        setWeight([]);
        setLength([]);
        setSize([]);
    }, [categoryId]);

    useEffect(() => {
        getAvailableFilters();
    }, [uiProductList]);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handlePurityChange = (item: string) => {
        if (purity.includes(item)) {
            return setPurity(purity.filter(itemPurity => itemPurity !== item));
        } return setPurity(purity.concat(item));
    };

    const getAvailableFilters = () => {
        let productsToBeFiltered = uiProductList;

        if (!productsToBeFiltered.length) productsToBeFiltered = productList;

        if (productsToBeFiltered) {
            let allPurity: string[] = [];
            let allWeight: number[] = [];
            let allLength: number[] = [];
            let allSize: number[] = [];

            productsToBeFiltered.forEach(product => {
                product.filterAttributes?.forEach(attribute => {
                    if (attribute.name === 'Purity') {
                        const { value } = attribute;

                        if (value.length) {
                            const convertedPurity = value.map(item => item.toString());
                            allPurity = allPurity.concat(convertedPurity);
                        } return false;
                    } if (attribute.name === 'Weight') {
                        const { value } = attribute;

                        if (value.length) {
                            const convertedWeight = value.map(item => Number(item));
                            allWeight = allWeight.concat(convertedWeight);
                        } return false;
                    } if (attribute.name === 'Length') {
                        const { value } = attribute;

                        if (value.length) {
                            const convertedLength = value.map(item => Number(item));
                            allLength = allLength.concat(convertedLength);
                        } return false;
                    } if (attribute.name === 'Size') {
                        const { value } = attribute;

                        if (value.length) {
                            const convertedSize = value.map(item => Number(item));
                            allSize = allSize.concat(convertedSize);
                        } return false;
                    } return false;
                });
            });

            if (allPurity) {
                const sortedPurities = [...new Set(allPurity)].sort().filter(item => item !== '-');

                if (sortedPurities.length) setPurityRange(sortedPurities);
            }

            if (allWeight) {
                const sortedWeights = [...new Set(allWeight)].sort().filter(item => !Number.isNaN(item));
                if (sortedWeights.length) {
                    const minWeight = Math.min(...sortedWeights);
                    const maxWeight = Math.max(...sortedWeights);

                    if (minWeight !== maxWeight) setWeightRange([minWeight, maxWeight]);
                }
            }

            if (allLength) {
                const sortedLength = [...new Set(allLength)].sort().filter(item => !Number.isNaN(item));
                if (sortedLength.length) {
                    const minLength = Math.min(...sortedLength);
                    const maxLength = Math.max(...sortedLength);
                    if (minLength !== maxLength) setLengthRange([minLength, maxLength]);
                }
            }

            if (allSize) {
                const sortedSize = [...new Set(allSize)].map(item => Number(item)).sort().filter(item => !Number.isNaN(item));
                if (sortedSize.length) {
                    const minSize = Math.min(...sortedSize);
                    const maxSize = Math.max(...sortedSize);
                    if (minSize !== maxSize) setSizeRange([minSize, maxSize]);
                }
            }
        }
    };

    const renderPurityCheckboxes = () => {
        if (!purityRange) return <Label> - </Label>;

        return purityRange.map((item: string, index: number) => (
            <Row key={item} style={{ paddingLeft: '20px', paddingRight: '30px' }}>
                <CustomInput
                    type='checkbox'
                    checked={purity.includes(item)}
                    onChange={() => handlePurityChange(item)}
                    id={item.concat(index.toString())}
                />
                <Label for={item.concat(index.toString())}>
                    {item}
                </Label>
            </Row>
        ));
    };

    useEffect(() => {
        const productFilters: filterParams = {
            filters: [
                {
                    name: 'Purity',
                    type: 'ENUM',
                    valuesSelected: purity,
                },
                {
                    name: 'Weight',
                    type: 'RANGE',
                    min: weight[0],
                    max: weight[1],
                },
                {
                    name: 'Length',
                    type: 'RANGE',
                    min: length[0],
                    max: length[1],
                },
                {
                    name: 'Size',
                    type: 'RANGE',
                    min: size[0],
                    max: size[1],
                },
            ],
        };

        filterProducts(productFilters);
    }, [purity, weight, length, size, uiProductList]);

    // Ref for the filterDropdown because it is a div element and will require extra functions to blur when clicked outside
    const filterDropdownRef = useRef<HTMLDivElement>(null);
    const filterDropdownButtonRef = useRef<HTMLButtonElement>(null);

    useLayoutEffect(() => {
        const handleClickOutside = (event: any) => {
            // Because the filterDropdownRef does not include the filter button so the button is an exception when checking for clicks (button ALSO toggles the dropdown)
            if (filterDropdownRef.current && !filterDropdownRef.current?.contains(event.target) && !filterDropdownButtonRef.current?.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterDropdownRef]);

    const renderFilterMenu = () => {
        if (!isOpen) return false;

        return (
            <div ref={filterDropdownRef} css={filterDropdownMenu}>
                <Row>
                    <div hidden={!purityRange.length} style={{ display: 'flex' }}>
                        <div style={{ paddingLeft: '20px', paddingRight: '25px' }}>
                            Purity
                        </div>
                        {renderPurityCheckboxes()}
                    </div>
                </Row>
                <Row>
                    <div hidden={!weightRange.length} style={{ padding: '20px 20px 10px', display: 'flex', width: '100%', alignItems: 'center' }}>
                        <div style={{ paddingRight: '20px', alignItems: 'center' }}>
                            Weight
                        </div>
                        <span style={{ marginRight: '15px' }}>{weight[0]?.toFixed(2) ? weight[0]?.toFixed(2) : weightRange[0]?.toFixed(2)}</span>
                        <Range
                            disabled={!weightRange.length}
                            min={weightRange[0]}
                            max={weightRange[1]}
                            defaultValue={weight.length ? weight : weightRange}
                            onAfterChange={(value) => setWeight(value)}
                            step={0.01}
                            allowCross={false}
                        />
                        <span style={{ marginLeft: '15px' }}>{weight[1]?.toFixed(2) ? weight[1]?.toFixed(2) : weightRange[1]?.toFixed(2)}</span>
                    </div>
                </Row>
                <Row>
                    <div hidden={!lengthRange.length} style={{ padding: '20px 20px 10px', display: 'flex', width: '100%', alignItems: 'center' }}>
                        <div style={{ paddingRight: '20px', alignItems: 'center' }}>
                            Length
                        </div>
                        <span style={{ marginRight: '15px' }}>{length[0]?.toFixed(2) ? length[0]?.toFixed(2) : lengthRange[0]?.toFixed(2)}</span>
                        <Range
                            disabled={!lengthRange.length}
                            min={lengthRange[0]}
                            max={lengthRange[1]}
                            defaultValue={length.length ? length : lengthRange}
                            onAfterChange={(value) => setLength(value)}
                            step={0.01}
                            allowCross={false}
                        />
                        <span style={{ marginLeft: '15px' }}>{length[1]?.toFixed(2) ? length[1]?.toFixed(2) : lengthRange[1]?.toFixed(2)}</span>
                    </div>
                </Row>
                <Row>
                    <div hidden={!sizeRange.length} style={{ padding: '20px 20px 10px', display: 'flex', width: '100%', alignItems: 'center' }}>
                        <div style={{ paddingRight: '20px', alignItems: 'center' }}>
                            Size
                        </div>
                        <span style={{ marginRight: '15px' }}>{size[0]?.toFixed(2) ? size[0]?.toFixed(2) : sizeRange[0]?.toFixed(2)}</span>
                        <Range
                            disabled={!sizeRange.length}
                            min={sizeRange[0]}
                            max={sizeRange[1]}
                            defaultValue={size.length ? size : sizeRange}
                            onAfterChange={(value) => setSize(value)}
                            step={0.1}
                            allowCross={false}
                        />
                        <span style={{ marginLeft: '15px' }}>{size[1]?.toFixed(2) ? size[1]?.toFixed(2) : sizeRange[1]?.toFixed(2)}</span>
                    </div>
                </Row>
            </div>
        );
    };
    return (
        <div style={{ paddingLeft: '10px' }}>
            <Row style={{ alignItems: 'start' }}>
                <button type='button' ref={filterDropdownButtonRef} onClick={() => toggle()} css={sortDropdownToggle}>
                    <div>
                        <img
                            css={productListingOptionsIcon}
                            src='/assets/icons/filter.svg'
                            alt='Funnel'
                        />
                        Filter
                    </div>
                </button>
                <Col>
                    {renderFilterMenu()}
                </Col>
            </Row>
        </div>
    );
};

interface filterParams {
    filters: {
        name: 'Purity' | 'Weight' | 'Length' | 'Size',
        type: 'ENUM' | 'RANGE',
        valuesSelected?: string[],
        min?: number,
        max?: number,
    }[]
}

const mapStateToProps = (state: RootState) => ({
    uiProductList: Selectors.getUiProductList(state),
    productList: Selectors.getProductsList(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    filterProducts: (filters: filterParams) => dispatch(Actions.uiFilterProducts(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
