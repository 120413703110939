import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkAPI } from 'Redux/types';

import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { IGoldPrice } from '../Entities';

export default createAsyncThunk<
    IGoldPrice | undefined,
    void,
    AsyncThunkAPI
>('home/getGoldPrice', async (params, thunkAPI) => {
    const response = await thunkAPI.extra.useCases.product.getGoldPrice.execute();

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }

    return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
});
