/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import { Image } from 'react-native-web';
import { connect } from 'react-redux';

import { Col, Container, Row, Badge, Spinner } from 'reactstrap';

import { css } from '@emotion/react';

import LoginSignUpModal from 'Containers/User/LoginSignUpModal';
import Searchbar from 'Components/Searchbar';
import Skeleton from 'Components/Skeletons/PageSkeleton';

import Actions from 'Redux/Actions';
import { RootState } from 'Redux/store';
import Selectors from 'Redux/Selectors';

import { ICartProduct, IProductCategory, IProductCollection } from '@ddot/AddToCartCommon/lib/entities';
import {
    menuContainer,
    headerContainer,
    headerCollapseContainer,
    headerCollapseSticky,
    sticky,
    headerIcon,
    headerIconContainer,
    headerLogo,
    headerColumnContainer,
    headerText,
    hoverTriangle,
    headerTextHighlight,
    headerMenuItem,
    headerMenuHeader,
    headerSearchHeader,
} from '../Styles/HeaderStyle';
import NavActions from '../../Services/Navigation/Actions';
import HoverCatalog from './HoverCatalog';
import Promo from './Promo';
import HeaderCollapse from './HeaderCollapse';

interface HeaderProps {
    categoriesLoading: boolean;
    productListLoading: boolean;
    categories: IProductCategory[];
    collections: IProductCollection[];
    cartItems: ICartProduct[];
    setQuery: (query: string | '') => null;
    searchQuery: string;
    userId: string | undefined;
    isLoggedIn: string | undefined;
    startupLoading: boolean;
}

type HoverType = 'CATEGORY' | 'COLLECTION' | null;
type CatalogType = IProductCategory[] | IProductCollection[] | null;

const Header = (props: HeaderProps) => {
    const {
        categoriesLoading, productListLoading, categories, collections, cartItems, searchQuery, setQuery, isLoggedIn, startupLoading,
    } = props;
    const [isSticky, setSticky] = useState(false);

    const [hoverItem, setHoverItem] = useState<HoverType>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [isSelected, setIsSelected] = useState(window.location.pathname);

    const handleScreenResize = () => {
        if (window.innerWidth < 800) setIsMobile(true);
        else setIsMobile(false);
    };

    const handleScroll = () => {
        const offset = window.scrollY;

        if (offset > 45) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        window.addEventListener('resize', handleScreenResize);

        return () => {
            window.removeEventListener('resize', handleScreenResize);
        };
    }, []);

    useEffect(() => {
        setIsSelected(window.location.pathname);
    }, [window.location.pathname]);

    let catalogToShow: CatalogType = null;
    const ascCategory = categories.slice();

    const sortedCategory = ascCategory.sort((a, b) => {
        const idA = a.id.toLowerCase();
        const idB = b.id.toLowerCase();
        if (idA < idB) {
            return -1;
        }
        if (idA > idB) {
            return 1;
        }
        return 0;
    });

    if (hoverItem === 'CATEGORY') catalogToShow = sortedCategory;
    else if (hoverItem === 'COLLECTION') catalogToShow = collections;
    const handleHover = (item: HoverType) => setHoverItem(item);
    const onCloseHover = () => setHoverItem(null);

    const navToHome = () => {
        setIsSelected('/');
        NavActions.navToHome();
    };

    const navToProductListing = () => {
        setIsSelected('/categories');

        if (window.location.pathname !== '/categories') {
            NavActions.navToProductListing();
        }
    };

    const navToAboutUs = () => {
        setIsSelected('/about-us');
        NavActions.navToAboutUs();
    };

    const navToWishlist = () => {
        setIsSelected('/wishlist');
        if (isLoggedIn) {
            NavActions.navToWishlist();
        } else setLoginModalVisible(true);
    };

    const navToCart = () => {
        setIsSelected('/cart');
        NavActions.navToCart();
        // if (isLoggedIn) {
        //     NavActions.navToCart();
        // } else setLoginModalVisible(true);
    };

    const onUserPressed = () => {
        setIsSelected('/user');
        const { userId } = props;

        if (userId) NavActions.navToUserProfile();
        else setLoginModalVisible(true);
    };

    const onAuthorized = () => {
        setLoginModalVisible(false);
    };

    const onSignup = () => {
        setLoginModalVisible(true);
    };

    const renderHover = () => {
        const renderItem = (item: HoverType) => {
            if (hoverItem !== item) return false;

            return (
                <Col>
                    <div
                        onMouseEnter={() => handleHover(hoverItem)}
                        onMouseLeave={onCloseHover}
                        css={hoverTriangle}
                    />
                </Col>
            );
        };

        return (
            <Row
                css={{
                    position: 'absolute',
                    height: 'auto',
                    width: '100%',
                }}
            >
                <Col />
                {renderItem('CATEGORY')}
                {renderItem('COLLECTION')}
                <Col />
            </Row>
        );
    };

    const handleOnKeyDown = (e: any) => {
        const { value } = e.target;
        if (e.key === 'Enter') {
            if (value.trim()) NavActions.navToProductSearch(value);
        }
    };

    const handleSearch = () => {
        if (searchQuery.trim()) NavActions.navToProductSearch(searchQuery);
    };

    const renderIcon = (icon: string, onClick: () => void, isCart?: boolean) => {
        const renderBadge = (): JSX.Element | null => {
            if (!isCart || !cartItems.length) return null;

            return (
                <Badge color='danger'>{cartItems.length}</Badge>
            );
        };

        if (startupLoading) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Skeleton height={24} width={24} row={1} />
                </div>
            );
        }

        return (
            <Col style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    onClick={startupLoading ? undefined : onClick}
                    onKeyPress={startupLoading ? undefined : onClick}
                    role='button'
                    tabIndex={0}
                >
                    <img
                        css={headerIcon}
                        src={icon}
                        alt=''
                    />
                </div>
                {renderBadge()}
            </Col>
        );
    };

    const renderWebHeader = () => {
        if (isMobile) return false;

        const renderSearchHeader = () => {
            return (
                <div style={{ flex: 1 }}>
                    <Searchbar
                        loading={productListLoading}
                        placeholder='Search'
                        onChange={setQuery}
                        onKeyDown={handleOnKeyDown}
                        onClicked={handleSearch}
                        value={searchQuery}
                        mainCss={headerSearchHeader}
                        inputCss={css({ width: 250 })}
                    />
                </div>
            );
        };

        const renderMenuHeader = () => {
            return (
                <div css={headerMenuHeader}>
                    {renderHover()}
                    <Row style={{ alignItems: 'center', width: '100%' }}>
                        <Col css={headerMenuItem}>
                            <div
                                css={isSelected === '/' ? headerTextHighlight : headerText}
                                onClick={navToHome}
                                onKeyPress={navToHome}
                                role='button'
                                tabIndex={0}
                            >
                                Home
                            </div>
                        </Col>
                        <Col
                            css={headerMenuItem}
                            onMouseEnter={() => handleHover('CATEGORY')}
                            onMouseLeave={onCloseHover}
                        >
                            <div
                                css={isSelected.includes('/categories') ? headerTextHighlight : headerText}
                                onClick={navToProductListing}
                                onKeyPress={navToProductListing}
                                role='button'
                                tabIndex={0}
                            >
                                Jewellery
                            </div>
                        </Col>
                        <Col
                            css={headerMenuItem}
                        // onMouseEnter={() => handleHover('COLLECTION')}
                        // onMouseLeave={onCloseHover}
                        >
                            <div
                                css={headerText}
                                onClick={() => {
                                    // NavActions.navToProductListing();
                                    // setPath(window.location.pathname);
                                }}
                                onKeyPress={() => {
                                    // NavActions.navToProductListing();
                                    // setPath(window.location.pathname);
                                }}
                                role='button'
                                tabIndex={0}
                            >
                                Collections
                            </div>
                        </Col>
                        <Col css={headerMenuItem}>
                            <div
                                css={isSelected.includes('/about-us') ? headerTextHighlight : headerText}
                                onClick={navToAboutUs}
                                onKeyPress={navToAboutUs}
                                role='button'
                                tabIndex={0}
                            >
                                About Us
                            </div>
                        </Col>
                        {/* <Col md={4} lg={3} css={headerMenuItem} style={{ position: 'absolute', right: '0' }}>
                        {renderSearchBarAndIcon()}
                    </Col> */}
                    </Row>
                </div>
            );
        };

        return (
            <Col
                className='d-md-flex d-lg-flex'
                lg={8}
                md={7}
                css={headerColumnContainer}
            >
                {renderSearchHeader()}
                {renderMenuHeader()}
            </Col>
        );
    };

    const renderMobileSearchbar = (): JSX.Element | null => {
        if (!isMobile) return null;

        return (
            <Searchbar
                loading={productListLoading}
                placeholder='Search'
                onChange={setQuery}
                onKeyDown={handleOnKeyDown}
                onClicked={handleSearch}
                value={searchQuery}
                inputCss={css({ width: '100%' })}
            />
        );
    };

    const imageHeight = isMobile ? 60 : 100;
    const imageWidth = isMobile ? 60 : 100;

    return (
        <div>
            <Promo />
            <div style={{ minHeight: isSticky ? 100 : 0 }} />
            <div css={[headerContainer, isSticky ? sticky : '']}>
                <Container fluid css={menuContainer}>
                    <Row className='m-0'>
                        <Col xs={4} md={2} lg={2}>
                            <div
                                onClick={navToHome}
                                onKeyPress={navToHome}
                                role='button'
                                tabIndex={0}
                            >
                                <Image
                                    source={{ uri: '/assets/header/v1-logo.png' }}
                                    style={{ height: imageHeight, width: imageWidth }}
                                    resizeMode='contain'
                                    css={headerLogo}
                                />
                            </div>
                        </Col>
                        {renderWebHeader()}
                        <Col xs={8} md={3} lg={2} css={headerIconContainer}>
                            <Row xs={4} style={{ justifyContent: 'space-around', paddingRight: 5 }}>
                                {renderIcon('/assets/header/person.svg', onUserPressed)}
                                {renderIcon('/assets/header/heart.svg', navToWishlist)}
                                {renderIcon('/assets/header/cart-bag.svg', navToCart, true)}
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <HoverCatalog
                    onMouseEnter={() => handleHover(hoverItem)}
                    onMouseLeave={onCloseHover}
                    show={hoverItem !== null}
                    loading={categoriesLoading}
                    catalog={catalogToShow}
                    onDismissHover={onCloseHover}
                />
            </div>
            {renderMobileSearchbar()}
            <div css={[headerCollapseContainer, isSticky ? headerCollapseSticky : '']}>
                {isMobile && (<HeaderCollapse />)}
            </div>

            <LoginSignUpModal
                toggle={() => setLoginModalVisible(!loginModalVisible)}
                modal={loginModalVisible}
                onAuthorized={onAuthorized}
                onSignup={onSignup}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    categoriesLoading: Selectors.getProductsCategoriesAttempting(state),
    productListLoading: Selectors.getProductsListAttempting(state),
    categories: Selectors.getProductsCategories(state),
    collections: Selectors.getProductsCollections(state),
    cartItems: Selectors.getCartItems(state),
    searchQuery: Selectors.getUiSearchQuery(state),
    userId: Selectors.getUserId(state),
    isLoggedIn: Selectors.getAuthAuthToken(state),
    startupLoading: Selectors.getAuthStartupAttempting(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    setQuery: (query: string | '') => dispatch(Actions.uiSetSearchQuery(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
