import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkAPI } from 'Redux/types';
import Selectors from 'Redux/Selectors';
import Toast from 'Lib/Toast';
import { IProduct } from '@ddot/AddToCartCommon/lib/entities';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { ErrorTypes } from '.';

interface AddToWishlistThunkParams {
    product: IProduct;
}

export default createAsyncThunk<
    IProduct,
    AddToWishlistThunkParams,
    AsyncThunkAPI
>('user/addToWishListLocalWeb', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const { product } = params;
    const userId = Selectors.getUserId(state);

    const response = await thunkAPI.extra.useCases.user.addToWishlistLocalWeb.execute({ userId, product });

    if (response.status === UseCaseResponseStatus.Success) {
        Toast.show('Item added to wishlist!');

        return product;
    }

    if (response.data.type === ErrorTypes.AddToWishlistLocalWebError.AlreadyInWishlist) {
        Toast.showError('This item is already in your wishlist.');
        return thunkAPI.rejectWithValue('This item is already in your wishlist.');
    }

    return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong, please try again');
});
