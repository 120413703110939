import ATCObject from '@ddot/AddToCartCommon';
import Config from 'Config/Config';

const Atc = new ATCObject({
    upshopAccountId: `${process.env.REACT_APP_UPSHOPID}`,
    jemisysBaseUrl: `${process.env.REACT_APP_JEMISYSBASEURL}`,
    jemisysBaseUrlApiKey: `${process.env.REACT_APP_JEMISYSBASEURL_APIKEY}`,
    env: Config.ENV as 'dev' | 'staging' | 'prod',
});

export default Atc;
