import { JemisysErrorTypes } from '@ddot/AddToCartCommon/lib/useCase';

import userAddToWishListLocalWeb from './addToWishlistLocalWeb';
import userGetWishlistLocalWeb from './getWishlistLocalWeb';
import userRemoveFromWishlistLocalWeb from './removeFromWishlistLocalWeb';
import userGetUserInfo from './getUserInfo';
import userUpdateUserInfo from './updateUserInfo';
import userUpdatePassword from './updatePassword';
import userGetOrderHistory from './getOrderHistory';
import userGetOrderHistoryDetails from './getOrderHistoryDetails';

export const ErrorTypes = JemisysErrorTypes.UserErrorTypes;

export default {
    userAddToWishListLocalWeb,
    userGetWishlistLocalWeb,
    userRemoveFromWishlistLocalWeb,
    userGetUserInfo,
    userUpdateUserInfo,
    userUpdatePassword,
    userGetOrderHistory,
    userGetOrderHistoryDetails,
};
