import React from 'react';
import { View } from 'react-native-web';
import { connect } from 'react-redux';

import PageSkeleton from 'Components/Skeletons/PageSkeleton';

import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import { IProductWidget } from '@ddot/AddToCartCommon/lib/entities';
import CarouselRow from './Components/CarouselRow';
import ProductsRow from './Components/ProductsRow';
import ProductWidget from './Components/ProductWidget';

interface HomeProps {
    productWidgetsLoading: boolean;
    productWidgets: IProductWidget[] | undefined;
}

const Home = (props: HomeProps) => {
    const { productWidgets } = props;

    const renderWidgets = () => {
        const { productWidgetsLoading } = props;

        if (productWidgetsLoading) return <PageSkeleton />;
        if (!productWidgets || !productWidgets.length) return null;

        return productWidgets.map(item => <ProductWidget key={item.title} widget={item} />);
    };

    return (
        <View>
            <CarouselRow />
            {renderWidgets()}
            {/* <SummerCollection collection={collections[0]} />
            <AutumnCollection collection={collections[2]} /> */}
            <ProductsRow />
        </View>
    );
};

const mapStateToProps = (state: RootState) => ({
    productWidgets: Selectors.getHomeProductWidgets(state),
    productWidgetsLoading: Selectors.getHomeProductWidgetsAttempting(state),
});

export default connect(mapStateToProps)(Home);
