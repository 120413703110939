/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Col, FormGroup, Input, Label, FormFeedback } from 'reactstrap';

const ValidationCheck = (props) => {
    const {
        label,
        labelCss,
        value,
        type,
        errorMessage,
        name,
        formGroupCss,
        feedbackCss,
        invalid: parentInvalid,
        validationDisabled,
        css,
        setPropInvalid,
        ...rest
    } = props;

    const [defaultInvalid, setDefaultInvalid] = useState(false);

    const invalid = parentInvalid || defaultInvalid;
    const setInvalid = (valueProp) => {
        if (setInvalid) setDefaultInvalid(valueProp);
        else setDefaultInvalid(valueProp);
    };

    const postCodeCheck = /^[0-9]+$/;
    const emailCheck = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]/;
    const phoneNumberCheck = /^[0-9]+$/;
    const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
    const symbolCheck = /[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]+/;
    const sqlCheck = /['"]/;
    const addressCheck = /["']+/;

    const onBlur = () => {
        if (validationDisabled) return;
        if (name === 'country' || name === 'state') return;

        const test1 = !value || !value.trim().length || emojiRegex.test(value);

        if (value.length > 0) {
            if (name === 'email') {
                if (test1 || !emailCheck.test(value)) {
                    setInvalid(true);
                } else setInvalid(false);
            } else if (name === 'phonenumber') {
                if (test1 || !phoneNumberCheck.test(value)) {
                    setInvalid(true);
                } else setInvalid(false);
            } else if (name === 'address1') {
                if (test1 || addressCheck.test(value) || sqlCheck.test(value)) {
                    setInvalid(true);
                } else setInvalid(false);
            } else if (name === 'address2') {
                if (test1 || addressCheck.test(value) || sqlCheck.test(value)) {
                    setInvalid(true);
                } else setInvalid(false);
            } else if (name === 'postcode') {
                if (test1 || !postCodeCheck.test(value)) {
                    setInvalid(true);
                } else setInvalid(false);
            } else if (type === 'password') {
                if (test1) setInvalid(true);
                else setInvalid(false);
            } else if (test1 || symbolCheck.test(value)) {
                setInvalid(true);
            } else setInvalid(false);
        } else setInvalid(false);
    };

    return (
        <Col>
            <FormGroup css={formGroupCss}>
                {label && (
                    <Label css={labelCss}>
                        {label}
                    </Label>
                )}
                <Input
                    {...rest}
                    invalid={invalid}
                    onBlur={onBlur}
                    value={value}
                    type={type}
                    name={name}
                    css={css}
                />
                <FormFeedback css={feedbackCss}>
                    {errorMessage}
                </FormFeedback>
            </FormGroup>
        </Col>
    );
};

ValidationCheck.defaultProps = {
    label: '',
    value: '',
    errorMessage: '',
    name: '',
    validationDisabled: false,
};

ValidationCheck.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    errorMessage: PropTypes.string,
    name: PropTypes.string,
    validationDisabled: PropTypes.bool,
};

export default ValidationCheck;
