import { createAsyncThunk } from '@reduxjs/toolkit';
import Selectors from 'Redux/Selectors';
import { AsyncThunkAPI } from 'Redux/types';

import { IOrder } from '@ddot/AddToCartCommon/lib/entities';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon/lib/useCase';
import { OrderErrorTypes } from '../../cart/controllers';

export interface IGetOrderThunkResponse {
    order: IOrder;
}

export interface IGetOrderThunkParams {
    orderId: string;
}

export default createAsyncThunk<
    IGetOrderThunkResponse,
    IGetOrderThunkParams,
    AsyncThunkAPI
>('order/orders', async (params, thunkAPI) => {
    console.log('in thunk');

    const { orderId } = params;

    const response = await thunkAPI.extra.useCases.order.getOrderDetails.execute({ orderId });

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }

    if (response.data.type === OrderErrorTypes.GetOrderDetailsError.ServerError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
