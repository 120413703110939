import { JemisysErrorTypes } from '@ddot/AddToCartCommon/lib/useCase';

import homeGetLocation from './getLocation';
import homeGetFeaturedProducts from './getFeaturedProducts';
import homeGetGoldPrice from './getGoldPrice';
import homeGetHello from './getHello';
import homeGetProductWidgets from './getWidgets';

export const ErrorTypes = JemisysErrorTypes.LocationErrorTypes;

export default {
    homeGetLocation,
    homeGetFeaturedProducts,
    homeGetGoldPrice,
    homeGetHello,
    homeGetProductWidgets,
};
