import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetWishlistLocalWebResponse } from '@ddot/AddToCartCommon/lib/useCase/Jemisys/User/GetWishlist/GetWishlistLocalWebBase';
import { IOrder, IProduct, IUser } from '@ddot/AddToCartCommon/lib/entities';
import controllers from './controllers';
import userGetUserInfo from './controllers/getUserInfo';
import { IUser as IUserState } from './Entities';
import { ISignupThunkResponse } from '../auth/controllers/signup';

const initialState: IUserState = {
    actions: {
        userInfo: false,
        userIP: false,
        addToWishlist: false,
        wishlist: false,
        removeFromWishlist: false,
        updateUserInfo: false,
        updatePassword: false,
        orderHistory: false,
        orderHistoryDetails: false,
    },
    userInfo: {
        id: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        address: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            postCode: '',
            country: '',
        },
    },
    userIP: '',
    wishlist: [],
    memberPoints: 0,
    error: {
        userInfo: '',
        userIP: '',
        addToWishlist: '',
        wishlist: '',
        removeFromWishlist: '',
        updateUserInfo: '',
        updatePassword: '',
        orderHistory: '',
        orderHistoryDetails: '',
    },
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        updateUserInfo: (state, action: PayloadAction<ISignupThunkResponse>) => {
            state.userInfo = action.payload;
        },
        userClearUserInfo: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(controllers.userAddToWishListLocalWeb.pending, (state) => {
            state.actions.addToWishlist = true;
            state.error.addToWishlist = '';
        });
        builder.addCase(controllers.userAddToWishListLocalWeb.fulfilled, (state, action: PayloadAction<IProduct>) => {
            state.actions.addToWishlist = false;

            if (state.wishlist) {
                state.wishlist = state.wishlist.concat(action.payload);
            }
        });
        builder.addCase(controllers.userAddToWishListLocalWeb.rejected, (state, action) => {
            state.actions.addToWishlist = false;
            state.error.addToWishlist = action.payload;
        });
        builder.addCase(controllers.userGetWishlistLocalWeb.pending, (state) => {
            state.actions.wishlist = true;
            state.error.wishlist = '';
        });
        builder.addCase(controllers.userGetWishlistLocalWeb.fulfilled, (state, action: PayloadAction<GetWishlistLocalWebResponse>) => {
            state.actions.wishlist = false;

            if (action.payload) {
                state.wishlist = action.payload.wishlist;
            }
        });
        builder.addCase(controllers.userGetWishlistLocalWeb.rejected, (state, action) => {
            state.actions.wishlist = false;
            state.error.wishlist = action.payload;
        });
        builder.addCase(controllers.userRemoveFromWishlistLocalWeb.pending, (state) => {
            state.actions.removeFromWishlist = true;
            state.error.removeFromWishlist = '';
        });
        builder.addCase(controllers.userRemoveFromWishlistLocalWeb.fulfilled, (state, action: PayloadAction<string>) => {
            state.actions.removeFromWishlist = false;

            if (state.wishlist) {
                state.wishlist = state.wishlist.filter(item => item.id !== action.payload);
            }
        });
        builder.addCase(userGetUserInfo.pending, (state) => {
            state.actions.userInfo = true;
            state.error.userInfo = '';
        });
        builder.addCase(userGetUserInfo.fulfilled, (state, action: PayloadAction<IUser>) => {
            state.actions.userInfo = false;

            if (action.payload) {
                state.userInfo = action.payload;
            }
        });
        builder.addCase(userGetUserInfo.rejected, (state, action) => {
            state.actions.userInfo = false;
            if (action.payload) {
                state.error.userInfo = action.payload;
            }
        });
        builder.addCase(controllers.userUpdateUserInfo.pending, (state) => {
            state.actions.updateUserInfo = true;
            state.error.updateUserInfo = '';
        });
        builder.addCase(controllers.userUpdateUserInfo.fulfilled, (state, action: PayloadAction<IUser>) => {
            state.actions.updateUserInfo = false;
            state.userInfo = action.payload;
        });
        builder.addCase(controllers.userUpdateUserInfo.rejected, (state, action) => {
            state.actions.updateUserInfo = false;

            if (action.payload) state.error.updateUserInfo = action.payload;
        });
        builder.addCase(controllers.userUpdatePassword.pending, (state) => {
            state.actions.updatePassword = true;
            state.error.updatePassword = '';
        });
        builder.addCase(controllers.userUpdatePassword.fulfilled, (state) => {
            state.actions.updatePassword = false;
        });
        builder.addCase(controllers.userUpdatePassword.rejected, (state, action) => {
            state.actions.updatePassword = false;

            if (action.payload) state.error.updatePassword = action.payload;
        });
        builder.addCase(controllers.userGetOrderHistory.pending, (state) => {
            state.actions.orderHistory = true;
            state.error.orderHistory = '';
        });
        builder.addCase(controllers.userGetOrderHistory.fulfilled, (state, action: PayloadAction<IOrder[]>) => {
            state.actions.orderHistory = false;
            state.orderHistory = action.payload;
        });
        builder.addCase(controllers.userGetOrderHistory.rejected, (state, action) => {
            state.actions.orderHistory = false;
            state.error.orderHistory = action.payload;
        });
        builder.addCase(controllers.userGetOrderHistoryDetails.pending, (state) => {
            state.actions.orderHistoryDetails = true;
            state.error.orderHistoryDetails = '';
        });
        builder.addCase(controllers.userGetOrderHistoryDetails.fulfilled, (state, action: PayloadAction<IOrder>) => {
            state.actions.orderHistoryDetails = false;
            state.orderHistory = state.orderHistory?.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }

                return item;
            });
        });
        builder.addCase(controllers.userGetOrderHistoryDetails.rejected, (state, action) => {
            state.actions.orderHistoryDetails = false;
            state.error.orderHistoryDetails = action.payload;
        });
    },
});

export default {
    actions: uiSlice.actions,
    reducers: uiSlice.reducer,
};
