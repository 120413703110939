/** @jsxImportSource @emotion/react */
import React from 'react';

import Clickable from 'Components/Clickable';

import Utils from 'Lib/Utils';

import { IOrder } from '@ddot/AddToCartCommon/lib/entities';
import {
    OrderStyle,
    OrderStyleBold,
    PriceTextContainer,
    TotalTextStyle,
    PriceTextStyle,
    // ButtonContainer,
    // ButtonStyle1,
    OrderHistoryRow,
    // ButtonStyle2,
} from '../Styles/ComponentStyles';
// import ModalRating from './ModalRating';

interface HistoryProps {
    orderHistoryObject: IOrder;
    setIsDetails(isDetails: boolean): void;
    setorderItemSelected(item: IOrder): void;
}

const OrderHistoryItem = (props: HistoryProps): JSX.Element => {
    const { orderHistoryObject, setIsDetails, setorderItemSelected } = props;
    const { id, orderDate, totalPrice, currency, customerRemarks, customAttributes } = orderHistoryObject;

    const onClicked = () => {
        setIsDetails(true);
        setorderItemSelected(orderHistoryObject);
    };

    const renderOrderNo = () => {
        if (!customAttributes) return null;

        const orderNo = Utils.CustomAttr.getValueFromKey('OrderNo', customAttributes) as string;

        if (!orderNo) return null;

        return (
            <div>
                <span css={OrderStyleBold}>Order Number: </span>
                <span css={OrderStyle}>{orderNo}</span>
            </div>
        );
    };

    return (
        <div>
            <Clickable onClick={onClicked}>
                <div css={OrderHistoryRow}>
                    <div css={{ flex: 2 }}>
                        <div>
                            <span css={OrderStyleBold}>Order ID : </span>
                            <span css={OrderStyle}>{id}</span>
                        </div>
                        {renderOrderNo()}
                        <div>
                            <span css={OrderStyleBold}>Order Date : </span>
                            <span css={OrderStyle}>{orderDate}</span>
                        </div>
                        {customerRemarks && (
                            <div>
                                <span css={OrderStyleBold}>Remarks : </span>
                                <span css={OrderStyle}>{customerRemarks}</span>
                            </div>
                        )}
                    </div>

                    <div css={PriceTextContainer}>
                        <div css={TotalTextStyle}>Order Total</div>
                        <div css={PriceTextStyle}>
                            {`${(totalPrice)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${currency}`}
                        </div>
                    </div>
                </div>
            </Clickable>

            {/* <div css={ButtonContainer}>
                <Button css={ButtonStyle1} onClick={() => setIsDetails(true)}>
                    View Order Details
                </Button>
                <Button css={ButtonStyle2} onClick={toggle}>
                    ㅤ Rateㅤ
                </Button>
                <ModalRating modal={modal} toggle={toggle} />
            </div> */}

            <hr
                css={{
                    width: '100%',
                    border: '1px solid #CACACA',
                }}
            />
        </div>
    );
};

export default OrderHistoryItem;
