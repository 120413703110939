import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkAPI } from 'Redux/types';

import homeSlice from 'Redux/slices/home';
import NavActions from 'Services/Navigation/Actions';
import Atc from 'Lib/ATC';
import getGoldPrice from './getGoldPrice';

export interface IHelloResponse {
    isMaintenance: boolean;
    goldPrice: {
        gold375G?: number;
        gold750?: number;
        gold916?: number;
        gold925S?: number;
        gold999?: number;
        gold9999?: number;
        gold916A?: number;
        gold916B?: number;
    };
    toggableGoldPrice: {
        gold375G: {
            isActive: boolean;
            value: number;
        },
        gold750: {
            isActive: boolean;
            value: number;
        },
        gold916: {
            isActive: boolean;
            value: number;
        },
        gold916A: {
            isActive: boolean;
            value: number;
        },
        gold916B: {
            isActive: boolean;
            value: number;
        },
        gold999: {
            isActive: boolean;
            value: number;
        },
        gold9999: {
            isActive: boolean;
            value: number;
        },
        gold925: {
            isActive: boolean;
            value: number;
        }
    };
}

export default createAsyncThunk<
    IHelloResponse,
    void,
    AsyncThunkAPI
>('hello/getHello', async (params, thunkAPI) => {
    const response = await thunkAPI.extra.useCases.product.getHello.execute();

    const jemisysPricing = await thunkAPI.dispatch(getGoldPrice());

    if (response.status === Atc.UseCases.Types.UseCaseResponseStatus.Success) {
        const { isMaintenance, goldPrice, toggableGoldPrice } = response.data;

        thunkAPI.dispatch(homeSlice.actions.setIsMaintenance(isMaintenance));

        if (!isMaintenance) {
            // make sure no data is null/undefined
            // because need to send 0 when placing order
            const newGoldPrices = {
                gold375G: toggableGoldPrice.gold375G.value,
                gold750: toggableGoldPrice.gold750.value,
                gold916B: toggableGoldPrice.gold916B.value,
                gold916A: toggableGoldPrice.gold916A.value,
                gold916: toggableGoldPrice.gold916.value,
                gold925: toggableGoldPrice.gold925.value,
                gold999: toggableGoldPrice.gold999.value,
                gold9999: toggableGoldPrice.gold9999.value,
            };

            if (toggableGoldPrice.gold750.isActive) {
                newGoldPrices.gold750 = jemisysPricing.payload.gold750;
            }
            if (toggableGoldPrice.gold916.isActive) {
                newGoldPrices.gold916 = jemisysPricing.payload.gold916;
            }
            if (toggableGoldPrice.gold999.isActive) {
                newGoldPrices.gold999 = jemisysPricing.payload.gold999;
            }
            if (toggableGoldPrice.gold9999.isActive) {
                newGoldPrices.gold9999 = jemisysPricing.payload.gold9999;
            }

            thunkAPI.dispatch(homeSlice.actions.setGoldPrices(newGoldPrices));
        } else {
            NavActions.navResetToMaintenance();
        }

        return response.data;
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
