const MAX_NUMBER_OF_PRODUCTS_PER_PAGE = 12;

const GUEST_ID = '0160437817';
const GUEST_NAME = 'guest';

const CYBERSOURCE_PAYMENT_URL = process.env.REACT_APP_CYBERSOURCE_PAYMENT_URL;
const FPX_PAYMENT_URL = process.env.REACT_APP_FPX_PAYMENT_URL;

const MERCHANT_CALLBACK_URL = process.env.REACT_APP_MERCHANT_CALLBACK_URL;
const MERCHANT_RETURN_URL = process.env.REACT_APP_MERCHANT_RETURN_URL;

const CURRENCY_CODE = process.env.REACT_APP_CURRENCY_CODE;

const ENV = process.env.REACT_APP_ENV;

export default {
    MAX_NUMBER_OF_PRODUCTS_PER_PAGE,

    GUEST_ID,
    GUEST_NAME,

    CYBERSOURCE_PAYMENT_URL,

    MERCHANT_CALLBACK_URL,
    MERCHANT_RETURN_URL,
    CURRENCY_CODE,

    ENV,
};
