import { IOrder, IProduct, IUser } from '@ddot/AddToCartCommon/lib/entities';
import { IUser as IUserState } from './Entities';

const getUserInfoAttempting = (state: IUserState): boolean => state.actions.userInfo;
const getUserId = (state: IUserState): string => state.userInfo.id;

const getWishlistAttempting = (state: IUserState): boolean => state.actions.wishlist || false;
const getWishlistError = (state: IUserState): string => state.error.wishlist || '';
const getWishlist = (state: IUserState): IProduct[] => state.wishlist || [];

const getUserInfo = (state: IUserState): IUser | undefined => state.userInfo;
const getUserEmail = (state: IUserState): string | undefined => state.userInfo?.email;

const getUpdateInfoAttempting = (state: IUserState): boolean => state.actions.updateUserInfo || false;
const getUpdateInfoError = (state: IUserState): string => state.error.updateUserInfo || '';

const getRemoveFromWishlistAttempting = (state: IUserState): boolean => state.actions.removeFromWishlist || false;
const getRemoveFromWishlistError = (state: IUserState): string => state.error.removeFromWishlist || '';

const getUpdatePasswordAttempting = (state: IUserState): boolean => state.actions.updatePassword || false;
const getUpdatePasswordError = (state: IUserState): string => state.error.updatePassword || '';

const getOrderHistoryAttempting = (state: IUserState): boolean => state.actions.orderHistory || false;
const getOrderHistoryError = (state: IUserState): string => state.error.orderHistory || '';
const getOrderHistory = (state: IUserState): IOrder[] => state.orderHistory || [];

const getOrderHistoryDetailsAttempting = (state: IUserState): boolean => state.actions.orderHistoryDetails || false;
const getOrderHistoryDetailsError = (state: IUserState): string => state.error.orderHistoryDetails || '';

export default {
    getUserInfoAttempting,
    getUserId,

    getWishlistAttempting,
    getWishlistError,
    getWishlist,

    getUserInfo,
    getUserEmail,

    getUpdateInfoAttempting,
    getUpdateInfoError,

    getRemoveFromWishlistAttempting,
    getRemoveFromWishlistError,

    getUpdatePasswordAttempting,
    getUpdatePasswordError,

    getOrderHistoryAttempting,
    getOrderHistoryError,
    getOrderHistory,

    getOrderHistoryDetailsAttempting,
    getOrderHistoryDetailsError,
};
