import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICartProduct, IShippingAddress } from '@ddot/AddToCartCommon/lib/entities';
import Config from 'Config/Config';
import { ICart } from './Entities';
import { IAddToCartThunkResponse } from './controllers/addToCart';

import controllers from './controllers';

import getCart, { GetCartThunkResponse } from './controllers/getCart';
import { IRemoveFromCartThunkResponse } from './controllers/removeFromCart';

const initialState: ICart = {
    actions: {
        cart: true,
        addToCart: false,
        checkout: false,
        updateCartQuantity: false,
        removeFromCart: false,
        placeOrder: false,
        fpxBankList: false,
        cyberSourceInfo: false,
    },
    isGuest: true,
    cartId: '',
    products: [],
    voucher: undefined,
    shippingFee: 10, // hardcoded
    currency: 'MYR',
    totalItemPrice: 0,
    shippingAddress: {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        state: '',
        postCode: '',
        city: '',
        country: '',
        email: '',
        phoneNumber: '',
        customAttributes: [],
    },
    customerRemarks: '',
    fpxBankList: [],
    cyberSourceInfo: {
        accessKey: '',
        profileId: '',
        transactionUuid: '',
        signedFieldNames: '',
        signedDateTime: '',
        transactionType: '',
        referenceNumber: '',
        locale: '',
        amount: '',
        currency: '',
        signature: '',
        billToAddressCity: '',
        billToAddressCountry: '',
        billToAddressLine1: '',
        billToAddressLine2: '',
        billToEmail: '',
        billToSurname: '',
        billToForename: '',
    },
    eghlInfo: {
        Amount: '',
        CurrencyCode: `${Config.CURRENCY_CODE}`,
        CustEmail: '',
        CustIP: '',
        CustName: '',
        CustPhone: '',
        MerchantCallBackURL: `${Config.MERCHANT_CALLBACK_URL}`,
        MerchantReturnURL: `${Config.MERCHANT_RETURN_URL}`,
        OrderNumber: '',
        PageTimeout: '300',
        Param6: '',
        PaymentDesc: '',
        PaymentID: '',
        PymtMethod: '',
        ServiceID: 'KEI',
        TransactionType: 'SALE',
        HashValue: '',
    },
    error: {
        cart: '',
        addToCart: '',
        checkout: '',
        updateCartQuantity: '',
        removeFromCart: '',
        placeOrder: '',
        fpxBankList: '',
        cyberSourceInfo: '',
    },
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        cartSetShippingAddress: (state, action: PayloadAction<IShippingAddress>) => {
            state.shippingAddress = action.payload;
        },
        cartSetShippingFee: (state, action: PayloadAction<number>) => {
            state.shippingFee = action.payload;
        },
        cartSetCustomerRemarks: (state, action: PayloadAction<string>) => {
            state.customerRemarks = action.payload;
        },
        cartSetCartItemAvailableVariant: (state, action: PayloadAction<ICartProduct[]>) => {
            state.products = action.payload;
        },
        cartClearCart: (state) => {
            state.actions = {
                ...initialState.actions,
                cart: false,
            };
            state.products = [];
            state.cartId = '';
        },
        cartClearAddToCartError: (state) => {
            state.error.addToCart = '';
        },
        cartSetIsGuest: (state, action: PayloadAction<boolean>) => {
            state.isGuest = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCart.pending, (state) => {
            state.actions.cart = true;
            state.products = [];
            state.cartId = '';
            state.error.cart = '';
        });
        builder.addCase(getCart.fulfilled, (state, action: PayloadAction<GetCartThunkResponse>) => {
            state.actions.cart = false;
            state.products = action.payload.products;
            state.cartId = action.payload.cartId;
            state.error.cart = '';
        });
        builder.addCase(getCart.rejected, (state, action) => {
            state.actions.cart = false;
            if (action.payload) {
                state.error.cart = action.payload;
            }
        });
        builder.addCase(controllers.cartAddToCart.pending, (state) => {
            state.actions.addToCart = true;
            state.error.addToCart = '';
        });
        builder.addCase(controllers.cartAddToCart.fulfilled, (state, action: PayloadAction<IAddToCartThunkResponse>) => {
            state.actions.addToCart = false;
            state.error.addToCart = '';
            state.cartId = action.payload.cartId;
            state.products = action.payload.cartItems;
        });
        builder.addCase(controllers.cartAddToCart.rejected, (state, action) => {
            state.actions.addToCart = false;
            if (action.payload) {
                state.error.addToCart = action.payload;
            }
        });
        builder.addCase(controllers.cartUpdateCartQuantity.pending, (state) => {
            state.actions.updateCartQuantity = true;
            state.error.updateCartQuantity = '';
        });
        builder.addCase(controllers.cartUpdateCartQuantity.fulfilled, (state, action) => {
            const { updatedProduct } = action.payload;

            state.actions.updateCartQuantity = false;
            state.products = state.products.map((item) => {
                if (item.id === updatedProduct.id && item.selectedVariant.sku === updatedProduct.selectedVariant.sku) {
                    return updatedProduct;
                }
                return item;
            });
        });
        builder.addCase(controllers.cartUpdateCartQuantity.rejected, (state, action) => {
            state.actions.updateCartQuantity = false;
            if (action.payload) {
                state.error.updateCartQuantity = action.payload;
            }
        });
        builder.addCase(controllers.cartRemoveFromCart.pending, (state) => {
            state.actions.removeFromCart = true;
            state.error.removeFromCart = '';
        });
        builder.addCase(controllers.cartRemoveFromCart.fulfilled, (state, action: PayloadAction<IRemoveFromCartThunkResponse>) => {
            state.actions.removeFromCart = false;

            if (action.payload.removedProduct) {
                const { id, selectedVariant } = action.payload.removedProduct;
                const { sku } = selectedVariant;

                if (id && selectedVariant) {
                    state.products = state.products.filter((item: { id: string; selectedVariant: { sku: string; }; }) => {
                        if (item.id !== id && item.selectedVariant.sku !== sku) {
                            return item;
                        } if (item.id === id && item.selectedVariant.sku !== sku) {
                            return item;
                        } return false;
                    });
                }
            }
        });
        builder.addCase(controllers.cartRemoveFromCart.rejected, (state) => {
            state.actions.removeFromCart = false;
        });
        builder.addCase(controllers.cartPlaceOrder.pending, (state) => {
            state.actions.placeOrder = true;
            state.error.placeOrder = '';
        });
        builder.addCase(controllers.cartPlaceOrder.fulfilled, (state) => {
            state.actions.placeOrder = false;
        });
        builder.addCase(controllers.cartPlaceOrder.rejected, (state, action) => {
            state.actions.placeOrder = false;
            if (action.payload) state.error.placeOrder = action.payload;
        });
        builder.addCase(controllers.cartGetFpxBankList.pending, (state) => {
            state.actions.fpxBankList = true;
            state.error.fpxBankList = '';
        });
        builder.addCase(controllers.cartGetFpxBankList.fulfilled, (state, action) => {
            state.actions.fpxBankList = false;
            if (action.payload) state.fpxBankList = action.payload;
        });
        builder.addCase(controllers.cartGetFpxBankList.rejected, (state, action) => {
            state.actions.fpxBankList = false;
            if (action.payload) state.error.fpxBankList = action.payload;
        });
        builder.addCase(controllers.cartGetCyberSourceInfo.pending, (state) => {
            state.actions.cyberSourceInfo = true;
            state.error.cyberSourceInfo = '';
        });
        builder.addCase(controllers.cartGetCyberSourceInfo.fulfilled, (state, action) => {
            state.actions.cyberSourceInfo = false;
            if (action.payload) state.cyberSourceInfo = action.payload;
        });
        builder.addCase(controllers.cartGetCyberSourceInfo.rejected, (state, action) => {
            state.actions.cyberSourceInfo = false;
            if (action.payload) state.error.cyberSourceInfo = action.payload;
        });
    },
});

export default {
    actions: cartSlice.actions,
    reducers: cartSlice.reducer,
};
